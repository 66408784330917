<template>
  <div class="list">
    <div
      v-for="(column, colIndex) of items"
      :key="colIndex"
      class="column"
    >
      <div
        v-for="(record, index) of column"
        :key="index"
        class="record"
      >
        <span class="label">
          {{ record.title }}
          <AuTooltip
            v-if="record.tooltip"
            :description="record.tooltip"
          />

        </span>
        <div class="sep" />
        <div
          class="value"
          :style="record.valueStyle"
        >
          <AuDecimalStatic
            v-if="record.decimal"
            class="text"
            :value="record.value"
            :decimal="record.decimal"
            :indent="record.indent"
            :addition="record.addition"
          />
          <span
            v-else
            class="text"
          >
            {{ record.value }}
          </span>
          <img
            v-if="record.checkbox != byll"
            :src="record.checkbox ? '/img/project-card/success-mark.svg' : '/img/project-card/no-mark.svg'"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
};

</script>

<style lang="scss" scoped>

.list {
  display: flex;
  gap: 25px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .record {
    display: flex;
    align-items: end;
    gap: 5px;

    font-size: 14px;

    .label {
      color: var(--color-50);
    }

    .value {
      display: flex;
      align-items: center;
      color: var(--text-color);
      gap: 4px;
    }

    .sep {
      height: 2px;
      flex: 1;
      border-bottom: 1px dashed var(--color-50);
      margin-bottom: 4px;
      opacity: 0.5;
    }
  }
}
</style>