<template>
  <div>
    <div class="i-title">
      {{ $t("auCapitals.info.title") }}
    </div>
    <AuList :items="info" />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "InfoCapital",
  props: {
    isPool: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    country: {
      type: String,
      default: ""
    },
    company: {
      type: String,
      default: ""
    },
    dateStart: {
      type: String,
      default: ""
    },
    dateEnd: {
      type: String,
      default: ""
    },
    createdAt: {
      type: String,
      default: ""
    },
    profitability: {
      type: String,
      default: ""
    },
    monthlyProfitPercents: {
      type: String,
      default: "Not set"
    },
    cagrPercents: {
      type: String,
      default: "Not set"
    },
    totalProfitPercents: {
      type: String,
      default: "Not set"
    },
    dailyProfitPercents: {
      type: String,
      default: "Not set"
    }
  },
  computed: {
    info() {
      return this.isPool ? this.infoForPool : this.infoForInvest;
    },

    infoForInvest() {
      return [
        [
          { title: this.$t("auCapitals.projectCard.information.country"), value: this.countryInfo },
          { title: this.$t("auCapitals.projectCard.information.SPV"), value: this.companyInfo },
          { title: this.$t("auCapitals.projectCard.information.openDate"), value: this.dateStart },
          { title: this.$t("auCapitals.projectCard.information.closeDate"), value: this.dateEnd }
        ],
        [
          { title: this.$t("auCapitals.projectCard.information.dividends"), value: "Yes", checkbox: true },
          { title: this.$t("auCapitals.projectCard.information.equity"), value: "Yes", checkbox: true },
          { title: this.$t("auCapitals.projectCard.information.votingRight"), value: "No", checkbox: false },
          { title: this.$t("auCapitals.projectCard.information.specialConditions"), value: "No", checkbox: false }
        ]
      ];
    },

    infoForPool() {
      return [
        [
          { title: this.$t("auCapitals.projectCard.information.monthProfit"), value: this.monthlyProfitPercents !== "Not set" ? this.monthlyProfitPercents + "%" : this.monthlyProfitPercents },
          { title: this.$t("auCapitals.projectCard.information.totalProfit"), value: this.totalProfitPercents !== "Not set" ? this.totalProfitPercents + "%" : this.totalProfitPercents },
          { title: this.$t("auCapitals.projectCard.information.profit24"), value: this.dailyProfitPercents !== "Not set" ? this.dailyProfitPercents + "%" : this.dailyProfitPercents }
        ],
        [
          { title: this.$t("auCapitals.projectCard.information.cagr"), value: this.cagrPercents !== "Not set" ? this.cagrPercents + "%" : this.cagrPercents, tooltip: this.$t("projectPortfolioTracker.cagrTooltip") },
          { title: this.$t("auCapitals.projectCard.information.accountAge"), value: this.accountAge }
        ]
      ];
    },

    countryInfo() {
      return this.country.length > 0 ? this.country : this.$t("auCapitals.projectCard.notSet");
    },

    companyInfo() {
      return this.company.length > 0 ? this.company : this.$t("auCapitals.projectCard.notSet");
    },

    accountAge() {
      return `${this.notZeroDays} ${this.$t("auCapitals.projectCard.information.days")}`;
    },

    notZeroDays() {
      const days = moment().diff(moment(this.createdAt), "days");
      return days === 0 ? 1 : days;
    }
  }
};
</script>

<style scoped lang="scss">
.i-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.i-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 25px;
  margin-bottom: 15px;

  .record {
    display: flex;
    align-items: end;
    gap: 5px;

    .label {
      font-size: 12px;
      color: var(--color-50);
    }

    .value {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .text {
      font-size: 12px;
      color: var(--text-color);
    }

    .sep {
      height: 2px;
      flex: 1;
      border-bottom: 1px dashed var(--color-50);
      margin-bottom: 4px;
      opacity: 0.5;
    }
  }
}
</style>