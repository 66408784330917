<template>
  <div class="dao-main">
    <div class="header">
      <AuButton
        type="primary"
        @click="addOrEdit"
      >
        {{ $t(`daoPage.${editMode ? "editDao" : "addDao"}`) }}
      </AuButton>
      <AuButton @click="$emit('cancel')">
        {{ $t("daoPage.cancel") }}
      </AuButton>
    </div>
    <AuTabView resizable>
      <AuTabPanel :title="$t('daoPage.tabVote')">
        <div>
          {{ $t("daoPage.modalName") }}
        </div>
        <AuInput
          v-model="name"
          type="Text"
        />
        <div class="label">
          {{ $t("daoPage.modalDescription") }}
        </div>
        <AuInput
          v-model="description"
          text-area-heigth="100"
          type="TextArea"
        />
        <div class="label">
          {{ $t("daoPage.modalLimit") }}
        </div>
        <AuInput
          v-model="tokenLimit"
          type="Number"
        />
        <div class="label">
          {{ $t("daoPage.modalStart") }}
        </div>
        <AuInput
          v-model="startAt"
          type="Date"
          :min-date="new Date(new Date().getTime() + (24 * 60 * 60 * 1000))"
          :format="formatView"
          clearable
        />
        <div class="label">
          {{ $t("daoPage.modalEnd") }}
        </div>
        <AuInput
          v-model="endAt"
          type="Date"
          :min-date="new Date(new Date().getTime() + (24 * 60 * 60 * 1000))"
          :format="formatView"
          clearable
        />
        <div class="d-flex align-items-center label">
          <input
            v-model="useTokens"
            type="checkbox"
          >
          <span class="ms-1">{{ $t("daoPage.modalUseToken") }}</span>
        </div>
        <AuSelect
          v-if="useTokens"
          class="mb-3"
          :value="selectedToken"
          :options="tokensItems"
          :placeholder="$t('daoPage.modalSelectTokenPlaceholder')"
          @input="v => selectedToken = v"
        />
      </AuTabPanel>
      <AuTabPanel :title="$t('daoPage.tabAnswer')">
        <div
          v-for="(e, index) of elements"
          :key="index"
        >
          <div class="label">
            {{ $t("daoPage.modalAnswer") }}
          </div>
          <div class="elements_gap">
            <AuInput
              v-model="elements[index].name"
              type="Text"
            />
            <AuButton
              bordered
              border-radius="10"
              center
              @click="deleteElement(index)"
            >
              -
            </AuButton>
            <AuButton
              bordered
              border-radius="10"
              center
              @click="elements.push({ name: '' })"
            >
              +
            </AuButton>
          </div>
        </div>
      </AuTabPanel>
    </AuTabView>
  </div>
</template>

<script>
import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import moment from "moment/moment";
export default {
  name: "DaoForm",
  components: { AuTabPanel, AuTabView },
  props: {
    daoRequest: {
      type: Object,
      default: () => {}
    },
    tokensItems: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: null,
      description: null,
      tokenLimit: 0,
      startAt: null,
      endAt: null,
      useTokens: null,
      selectedToken: null,
      elements: [{ name: "" }]
    };
  },
  mounted() {
    if (this.editMode) {
      this.setData();
    }
    else {
      this.clearData();
    }
  },
  methods: {
    formatView(date) {
      return moment(date).locale("ru").format("L");
    },

    deleteElement(index) {
      if (this.elements.length > 1) {
        this.elements.splice(index, 1);
      }
    },

    clearData() {
      this.name = null;
      this.description = null;
      this.tokenLimit = 0;
      this.startAt = null;
      this.endAt = null;
      this.useTokens = null;
      this.selectedToken = null;
      this.elements = [{ name: "" }];
    },

    setData() {
      this.name = this.daoRequest.name;
      this.description = this.daoRequest.description;
      this.tokenLimit = this.daoRequest.tokenLimit;
      this.startAt = this.daoRequest.startAt;
      this.endAt = this.daoRequest.endAt;
      this.useTokens = this.daoRequest.useTokens;
      this.selectedToken = this.daoRequest.selectedToken;
      this.elements = this.daoRequest.elements;
    },

    addOrEdit() {
      this.$emit(this.editMode ? "edit" : "add", {
        name: this.name,
        description: this.description,
        tokenLimit: Number(this.tokenLimit),
        startAt: this.startAt,
        endAt: this.endAt,
        useTokens: this.useTokens,
        selectedToken: this.selectedToken,
        elements: this.elements
      });
    }
  }
};
</script>

<style scoped lang="scss">
.dao-main {
  .header {
    display: flex;
    gap: 10px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
  }

  .label {
    margin-top: 24px;
    margin-bottom: 6px;
  }

  .elements_gap{
    display: flex;
    gap: 5px;
  }
}
</style>