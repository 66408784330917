<template>
  <div v-if="$route.name ==='project card' || $route.name ==='outer project' || $route.name === 'portfolio explorer'">
    <AuBreadcrumb
      v-if="(!isIssuer || isOuter) && $route.name !== 'portfolio explorer'"
      parent-name="router.capital"
      :parent-path="parentPath"
      :loading="loading"
      :current-page-title="project.name"
    />
    <AuBreadcrumb
      v-if="$route.name === 'portfolio explorer'"
      :pages="breadcrumb"
    />
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="pc-main"
    >
      <RouterView v-if="$route.name === 'portfolio explorer'" />
      <div
        v-else
        class="card pc-fields pc-left-panel"
      >
        <ImageCapital
          class="pc-indent"
          v-bind="project"
          :is-voting="isVoting"
          :status="status"
          :status-color="statusColor"
          :status-icon="statusIcon"
          :is-autentic-project="project.isAutenticProject"
          :project-description-type="project.projectDescriptionType"
          :project-description-type-name="project.projectDescriptionTypeName"
        />
        <AuthorCapital
          class="pc-indent pc-hidden pc-fields"
          :class="{'pc-light': isLightTheme, 'pc-dark': !isLightTheme}"
          :user="project.user"
          :company="project.company"
        />
        <InvestBlock
          class="pc-indent pc-hidden"
          v-bind="project"
          :status="status"
          :status-color="statusColor"
          :timer="timer"
          @invest="openCard"
        />
        <div class="pc-separator pc-indent pc-hidden" />
        <DescriptionCapital
          :id="project.id"
          class="pc-indent"
          :description="project.descriptionEng"
          :application-type="project.applicationType"
        />
        <div class="pc-separator pc-indent" />
        <InfoCapital
          class="pc-indent"
          v-bind="project"
          :is-pool="project.applicationType === 'pool'"
        />
        <div class="pc-separator pc-indent" />
        <div v-if="project.applicationType != 'pool'">
          <Address
            v-if="address"
            class="pc-indent"
            :address="address"
          />
          <div
            v-else
            class="ac-text"
          >
            {{ $t("auCapitals.address.noAddress") }}
          </div>
        </div>
        <PortfolioExplorer
          v-if="project.applicationType == 'pool' && !addressPortfolio"
          :id="project.id"
          :project-type="project.projectType"
          class="pc-hidden pc-indent"
        />
        <Offers
          :id="project.id"
          :project-type="project.projectType"
          class="pc-hidden pc-indent"
        />
        <Documents
          v-if="!isOuter && documents.length"
          class="pc-hidden pc-indent"
          :files="project.files"
        />
        <NewsForCapital
          v-if="blogPages?.data.length > 0"
          :news-info="blogPages?.data"
          class="pc-hidden pc-indent"
        />
        <AuButton
          type="primary"
          height="61px"
          width="100%"
          center
          :disabled="status !== 'Open' || !isAllowed || project.progressProcent >= 100"
          @click="openCard"
        >
          <div class="pc-invest">
            <img
              :src="tokenIcon"
              alt="token"
            >
            <span>{{ $t(`auCapitals.investBlock.buttonTxt${vote}`) }}</span>
          </div>
        </AuButton>
      </div>
      <div class="pc-right-panel">
        <AuthorCapital
          class="card pc-fields pc-indent"
          :user="project.user"
          :company="project.company"
        />
        <PortfolioExplorer
          v-if="project.applicationType == 'pool' && !addressPortfolio"
          :id="project.id"
          :project-type="project.projectType"
          class="card pc-fields pc-indent"
        />
        <InvestBlock
          class="card pc-fields pc-indent"
          v-bind="project"
          :status="status"
          :status-color="statusColor"
          :timer="timer"
          @invest="openCard"
        />
        <Offers
          :id="project.id"
          :project-type="project.projectType"
          class="card pc-fields pc-indent"
        />
        <Documents
          v-if="!isOuter && documents.length"
          class="card pc-fields pc-indent"
          :files="project.files"
        />
        <NewsForCapital
          v-if="blogPages?.data.length > 0"
          :news-info="blogPages?.data"
          class="card pc-fields"
        />
      </div>
    </div>
    <CapitalModal
      v-if="showDialog"
      v-model="showDialog"
      :project="project"
      :invest-amount="investCount"
      @finish="investFinished"
    />
  </div>
  <RouterView v-else />
</template>

<script>
import _ from "lodash";
import Address from "@/components/capital/Address";
import AuthorCapital from "@/components/capital/AuthorCapital";
import BigNumber from "bignumber.js";
import CapitalModal from "@/components/capital/CapitalModal";
import DescriptionCapital from "@/components/capital/DescriptionCapital";
import Documents from "@/components/capital/Documents";
import ImageCapital from "@/components/capital/ImageCapital";
import InfoCapital from "@/components/capital/InfoCapital";
import InvestBlock from "@/components/capital/InvestBlock";
import NewsForCapital from "@/components/capital/NewsForCapital";
import Offers from "@/components/capital/Offers";
import PortfolioExplorer from "@/components/capital/PortfolioExplorer";
import ProgressLoader from "@/loaders/progress-bar";
import { convertCapital } from "@/components/capital/capitalHelper";
import { mapActions, mapGetters } from "vuex";
import { startTime } from "@/help/timerSupport.js";

export default {
  name: "ProjectCard2",
  components: {
    NewsForCapital,
    Offers,
    PortfolioExplorer,
    Address,
    AuthorCapital,
    InfoCapital,
    Documents,
    InvestBlock,
    DescriptionCapital,
    ImageCapital,
    CapitalModal,
    ProgressLoader
  },
  data() {
    return {
      loading: true,
      project: {},
      investCount: "",
      showDialog: false,
      adrArray: [
        "Deniz, Acıgöl Cd. No:71, 33200 Mezitli/Mersin, Турция",
        "Bořivojova 695/87, 130 00 Praha 3-Žižkov"
      ],
      tokenCount: "0",
      timerStatus: null,
      timerColor: null,
      timerIcon: null,
      timer: null,
      addressPortfolio: false
    };
  },
  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital",
      countries: "country",
      isLightTheme: "isLightTheme",
      blogPages: "blogPages",
      profile: "profile/profile",
      checkProfile: "profile/checkProfile"
    }),

    breadcrumb() {
      return [
        { key: 0, name: this.$t("router.capital"), path: this.parentPath },
        { key: 1, name: this.project?.name ?? "Project", path: `/project-card/${this.$route.params.id}` }
      ];
    },

    isOuter() {
      return this.$route.meta.outer == true;
    },

    isAllowed() {
      if (this.isIssuer) {
        return false;
      }
      if (!this.project.allowedForUsers) {
        return true;
      }
      return _.some(this.profile.roles, role => role == this.project.allowedForUsers);
    },

    parentPath() {
      return this.isOuter ? "/outer-projects" : "/projects";
    },

    isIssuer() {
      if (this.$auth.user()) {
        return this.$auth.user().type == "issuer";
      }
      return false;
    },

    projectId() {
      return this.$route.params.id;
    },

    documents() {
      return _.filter(this.project.files, file => file.type != "image" && !file.isVideoEmbedded);
    },

    countryOptions() {
      return _.map(this.countries, c => ({ display: c.name, value: c.code }));
    },

    country() {
      return _.chain(this.countryOptions)
        .find({ value: this.project.countryCode })
        .get("display")
        .value();
    },

    tokenIcon() {
      return this.project.tokenQuote?.logoMain ?? "/svg/companies/s_logo.svg";
    },

    isVoting() {
      return this.project.projectType === "voting";
    },

    vote() {
      return this.isVoting ? "Vote" : "";
    },

    address() {
      return this.project?.address;
    },
    status() {
      return this.timerStatus ?? this.project.status;
    },
    statusColor() {
      return this.timerColor ?? this.project.statusColor;
    },
    statusIcon() {
      return this.timerIcon ?? this.project.statusIcon;
    }
  },
  watch: {
    "$route"() {
      this.checkPortfolioExplorer();
    },
    projectId: {
      immediate: true,
      async handler() {
        this.loading = true;
        await this.getSectionCapital();
        await this.getCapital();
        await this.getProjectNews();
        this.loading = false;
      }
    },
    checkProfile: {
      immediate: true,
      async handler() {
        await this.updateProject();
      }
    }
  },

  async created() {
    this.checkPortfolioExplorer();
    await this.getCountry();
  },
  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAuCapitals: "capitals/getAuCapitals",
      getCountry: "getCountry",
      getBlogPages: "getBlogPages",
      getPortfolioTracker: "capitals/getPortfolioTracker"
    }),

    checkPortfolioExplorer() {
      const path = this.$route.path;
      this.addressPortfolio = path.includes("portfolio-explorer");
    },

    async getCapital() {
      try {
        if (!this.$route.params.id) {
          return;
        }
        const capital = await this.getAuCapitals(this.$route.params.id);
        this.project = convertCapital(capital, this.sectionCapital);
        this.project.country = this.country;
        this.project.address = capital?.address;
        this.project.user = capital?.user ?? {};
        this.project.feePercent = capital?.feePercent ?? "";
        this.project.tokenBase = capital?.tokenBase ?? {};
        this.project.tokenQuote = capital?.tokenQuote ?? {};
        this.project.smartContract = capital?.smartContract?.ethAddress?.value ?? "";
        this.project.expertVotes = capital?.expertVotes ?? "";
        this.project.minExpertVotes = capital?.minExpertVotes ?? "";
        this.project.hasEscrow = capital?.hasEscrow ?? "";
        this.project.terms = capital?.terms ?? "";
        this.project.applicationType = capital?.applicationType;
        this.project.createdAt = capital?.createdAt;
        await this.startTimerHandler();
        if (this.project.applicationType === "pool") {
          const params = {
            page: 1,
            itemsPerPage: 1,
            auCapitalId: this.id
          };

          const resp = await this.getPortfolioTracker(params);
          this.project.monthlyProfitPercents = resp.data[0]?.monthlyProfitPercents;
          this.project.cagrPercents = resp.data[0]?.cagrPercents;
          this.project.totalProfitPercents = resp.data[0]?.totalProfitPercents;
          this.project.dailyProfitPercents = resp.data[0]?.dailyProfitPercents;
        }
      }
      catch (e) {
        console.error("error", e);
      }
    },

    async startTimerHandler() {
      let rawStart = this.project.rawStart;
      let rawEnd = this.project.rawEnd;

      await startTime(rawStart, rawEnd, (data) => {
        Object.assign(this, data);
      });
    },

    async getProjectNews() {
      const param = {
        page: 1,
        auToken: this.project.tokenBase?.id
      };
      try {
        await this.getBlogPages(param);
      }
      catch (e) {
        console.error(e);
      }
    },

    openCard(count) {
      if (this.isOuter) {
        window.open(process.env.VUE_APP_HOST + "project-card/" + this.project.id + "?outer=true", "_blank");
      }
      else {
        this.investCount = count;
        this.showDialog = !this.showDialog;
      }
    },

    async investFinished() {
      if (process.env.VUE_APP_WEBSOCKET_ENABLED != "true") {
        await new Promise(r => setTimeout(r, 20000));
        await this.updateProject();
      }
    },

    async updateProject() {
      const capital = await this.getAuCapitals(this.$route.params.id);
      this.timerColor = null;
      this.timerStatus = null;
      this.timerIcon = null;

      this.project.currentCap = capital.currentCap;
      this.project.status = convertCapital(capital, this.sectionCapital).status;
      this.project.statusColor = convertCapital(capital, this.sectionCapital).statusColor;
      this.project.statusIcon = convertCapital(capital, this.sectionCapital).statusIcon;

      let percent = BigNumber(this.project.currentCap);
      percent = percent.multipliedBy(100);
      percent = percent.dividedBy(this.project.maxCap);
      this.project.progressProcent = percent.toNumber().toFixed(1);
    }
  }
};
</script>

<style scoped lang="scss">
.pc-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  @media screen and (max-width: 1000px)  {
    grid-template-columns: 1fr;
  }
}
.pc-fields {
  padding: 20px;
}
.pc-indent {
  margin-bottom: 25px;
}
.pc-separator {
  width: 100%;
  height: 3px;
  background-color: var(--sep-line-color);
}
.pc-hidden {
  display: none;
  @media screen and (max-width: 1000px)  {
    display: block;
  }
}
.pc-visible {
  display: flex;
  @media screen and (max-width: 1000px)  {
    display: none;
  }
}
.pc-left-panel {
  height: min-content;
}
.pc-right-panel {
  @media screen and (max-width: 1000px)  {
    display: none;
  }
}
.pc-light {
  background-color: #F2F1F7;
  border-radius: 20px;
}
.pc-dark {
  background-color: #0d1117;
  border-radius: 20px;
}
.pc-invest {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 22px;
  }
  img {
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(1);
  }
}
.ac-text {
  color: var(--color-50);
  margin-bottom: 20px;
}
</style>