import moment from "moment";
export default function formatDate(date, lang) {
  let strDate = moment(date).locale(lang).format("DD MMM YYYY");
  if (lang === "ru") {
    strDate = strDate.replace(".", "");
  }
  return strDate.slice(0, 3) + strDate.charAt(3).toUpperCase() + strDate.slice(4);
}

export function formatDateLang(language, date) {
  if (language === "ru") {
    return moment(date)
      .locale("ru")
      .format("DD MMM YYYY")
      .replace(/\./, "")
      .replace(/[а-я]+/g, (month) => month.charAt(0).toUpperCase() + month.slice(1));
  }
  return moment(date).locale("en").format("DD MMM YYYY");
}