<template>
  <div class="position-relative">
    <ImageCapitalIcons
      :is-voting="vote == 'Vote'"
      :is-autentic-project="isAutenticProject"
      :type="projectDescriptionType"
      :type-name="projectDescriptionTypeName"
      :tag="tag"
      :status="status"
      :icon="statusIcon"
      :color="statusColor"
    />
    <img
      v-if="images.length === 1 && icon"
      class="ic-image"
      :src="icon"
      :alt="name"
    >
    <Swiper
      v-else-if="images.length > 1"
      :navigation="true"
      :hash-navigation="{
        watchState: true,
      }"
      :modules="modules"
    >
      <SwiperSlide
        v-for="(file, index) in images"
        :key="index"
      >
        <ProjectCardEmbeddedImage
          v-if="file.isVideoEmbedded"
          :embedded-code="file.embeddedCode"
          :src="file.thumb"
          :alt="file.nameOriginal"
          target-class=".ic-image"
          main-class=".main-panel"
        />

        <img
          v-else
          class="ic-image"
          :src="file.thumb"
          :alt="file.nameOriginal"
        >
      </SwiperSlide>
    </Swiper>

    <div
      v-else
      class="ic-image ic-no-image"
    >
      <img
        src="/img/project-card/no-photo.svg"
        :alt="name"
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ImageCapitalIcons from "./ImageCapitalIcons";
import ProjectCardEmbeddedImage from "@/components/capital/ProjectCardEmbeddedImage";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "ImageCapital",

  components: {
    ProjectCardEmbeddedImage,
    Swiper,
    SwiperSlide,
    ImageCapitalIcons
  },

  props: {
    icon: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    statusIcon: {
      type: String,
      default: ""
    },
    statusColor: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: ""
    },
    isVoting: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: () => []
    },
    isAutenticProject: {
      type: Boolean,
      default: false
    },
    projectDescriptionType: {
      type: String,
      default: ""
    },
    projectDescriptionTypeName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      modules: [Navigation]
    };
  },
  computed: {
    style() {
      return {
        "--status-color": this.statusColor
      };
    },

    images() {
      const images = _.filter(this.files, file => file.type == "image" || file.isVideoEmbedded);

      if (this.icon) {
        return [{ thumb: this.icon, nameOriginal: this.name }, ...images];
      }

      return images;
    }
  }
};
</script>

<style scoped lang="scss">
.ic-image {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  object-fit: cover;
}

.ic-no-image {
  background: var(--color-10);
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>