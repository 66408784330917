import moment from "moment";

export function startTime(rawStart, rawEnd, callback) {
  const waiting = moment().isBefore(rawStart)
    ? {
      moment: moment(rawStart),
      status: "Open",
      icon: "rocketc",
      color: "#2aba7c",
      text: ""
    }
    : moment().isBefore(rawEnd)
      ? {
        moment: moment(rawEnd),
        status: "Closed",
        icon: "rocketc",
        color: "#C0C0C0",
        text: ""
      }
      : null;

  if (waiting) {
    const diff = waiting.moment.diff(moment(), "seconds");
    let ticker = diff < 24 * 60 * 60 && diff > 0 ? moment.duration(diff, "seconds") : null;
    let timer = ticker ? formatTimer(waiting.text, ticker) : null;
    callback({ timer });

    const intervalHandle = setInterval(() => {
      if (moment().isAfter(waiting.moment)) {
        clearInterval(intervalHandle);
        callback({
          timer: null,
          timerStatus: waiting.status,
          timerIcon: waiting.icon,
          timerColor: waiting.color
        });
        if (waiting.status === "Open") {
          startTime(rawStart, rawEnd, callback);
        }
      }

      if (ticker) {
        ticker = ticker.subtract(1, "seconds");
        callback({ timer: formatTimer(waiting.text, ticker) });
      }
    }, 1000);
  }
}

function formatTimer(text, duration) {
  let time = duration.hours();
  let epilogue = "hours";
  if (time === 0) {
    time = duration.minutes();
    epilogue = "minutes";
    if (time === 0) {
      time = duration.seconds();
      epilogue = "seconds";
    }
  }
  return `${text} ${time} ${epilogue}`;
}
