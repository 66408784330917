<template>
  <ProgressLoader
    v-if="breadcrumbLoading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />
  <div v-else>
    <AuBreadcrumb :pages="breadcrumb" />
    <div class="card">
      <div class="header">
        <div class="top-header">
          <div>
            <div class="title">
              {{ $t("router.pages.projectCreatePortfolioTracker") }}
            </div>
            <div class="title">
              <span class="explain">
                {{ $t("auCapitals.capitalHistory.project") }}
                <span class="data">{{ currentCapital?.tokenBase?.nameEng }}
                </span>
              </span>
            </div>
          </div>

          <AuButton
            class="btn-add"
            bordered
            center
            @click="goBack"
          >
            <span class="bnt-text">
              {{ $t("projectPortfolioTracker.cancel") }}
            </span>
          </AuButton>
        </div>
      </div>

      <div class="i-block">
        <div class="i-line-half">
          <div class="i-title">
            {{ $t("projectPortfolioTracker.date") }}
          </div>
          <AuInput
            :model-value="date"
            :max-date="maxDate"
            :format="formatView"
            :enable-minutes="false"
            type="Date"
            required
            clearable
            :placeholder="$t('projectPortfolioTracker.datePlaceholder')"
            @update:modelValue="setDate"
          />
        </div>
      </div>

      <div class="i-block">
        <div class="i-left">
          <div class="i-title">
            {{ $t("projectPortfolioTracker.overallStatistic") }}
          </div>

          <div class="i-label">
            {{ $t("projectPortfolioTracker.monthlyProfit") }}
          </div>
          <AuInput
            v-model="monthlyProfitPercents"
            :placeholder="$t('projectPortfolioTracker.monthlyProfitPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.cagr") }}<AuTooltip :description="$t('projectPortfolioTracker.cagrTooltip')" />
          </div>
          <AuInput
            v-model="cagrPercents"
            :placeholder="$t('projectPortfolioTracker.cagrPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.totalProfit") }}
          </div>
          <AuInput
            v-model="totalProfitPercents"
            :placeholder="$t('projectPortfolioTracker.totalProfitPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.dailyProfit") }}
          </div>
          <AuInput
            v-model="dailyProfitPercents"
            :placeholder="$t('projectPortfolioTracker.dailyProfitPlaceholder')"
            type="NullableNumber"
          />
        </div>

        <div class="i-right">
          <div class="i-title">
            {{ $t("projectPortfolioTracker.detailStatistic") }}
          </div>

          <div class="i-label">
            {{ $t("projectPortfolioTracker.balanceLabel") }}
          </div>
          <AuInput
            v-model="balance"
            required
            :placeholder="$t('projectPortfolioTracker.balancePlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.profitLabel") }}
          </div>
          <AuInput
            v-model="profit"
            required
            :placeholder="$t('projectPortfolioTracker.profitPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.drawdownLabel") }}
          </div>
          <AuInput
            v-model="drawdownPercents"
            required
            :placeholder="$t('projectPortfolioTracker.drawdownPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.sharpCoefLabel") }}
          </div>
          <AuInput
            v-model="sharpCoef"
            required
            :placeholder="$t('projectPortfolioTracker.sharpCoefPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.roi") }}<AuTooltip :description="$t('projectPortfolioTracker.roiTooltip')" />
          </div>
          <AuInput
            v-model="roi"
            :placeholder="$t('projectPortfolioTracker.roiPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.maxMonthlyProfit") }}
          </div>
          <AuInput
            v-model="maxMonthlyProfitPercents"
            :placeholder="$t('projectPortfolioTracker.maxMonthlyProfitPlaceholder')"
            type="NullableNumber"
          />
          <div class="i-label">
            {{ $t("projectPortfolioTracker.maxMonthlyDrawdown") }}
          </div>
          <AuInput
            v-model="maxMonthlyDrawdownPercents"
            :placeholder="$t('projectPortfolioTracker.maxMonthlyDrawdownPlaceholder')"
            type="NullableNumber"
          />
        </div>
      </div>

      <AuButton
        v-if="voteId"
        :disabled="!isSaveBtn"
        width="100%"
        type="primary"
        center
        @click="editPortfolio"
      >
        {{ $t("projectPortfolioTracker.saveBtn") }}
      </AuButton>
      <AuButton
        v-else
        :disabled="!isSaveBtn"
        width="100%"
        type="primary"
        center
        @click="savePortfolio"
      >
        {{ $t("projectPortfolioTracker.saveBtn") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectPortfolioTracker",

  components: {
    ProgressLoader
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentCapital: [],
      currentVote: [],
      breadcrumbLoading: true,
      voteId: null,
      monthlyProfitPercents: null,
      cagrPercents: null,
      totalProfitPercents: null,
      dailyProfitPercents: null,
      balance: null,
      profit: null,
      drawdownPercents: null,
      sharpCoef: null,
      roi: null,
      maxMonthlyProfitPercents: null,
      maxMonthlyDrawdownPercents: null,
      tokensCountHasError: false,
      date: null
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      ethAddresses: "ethAddresses",
      portfolioTotal: "capitals/portfolioTotal"
    }),

    isSaveBtn() {
      return this.balance && this.profit && this.drawdownPercents && this.sharpCoef;
    },

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("projects.label"), path: "/my-projects" }
      ];
      if (this.projectName) {
        breadcrumb.push({
          key: breadcrumb?.length,
          name: this.projectName,
          path: `/project-card/${this.id}`
        });
      }
      return breadcrumb;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    projectName() {
      return this.currentCapital?.tokenBase?.nameEng || null;
    },

    maxDate() {
      return new Date(new Date());
    }
  },

  async mounted() {
    if (this.$auth.user().type != "issuer") {
      this.$router.push({ name: "base" });
    }
    this.voteId = this.$route.query?.voteId;
    this.currentCapital = await this.getAuCapitals(this.id);
    this.breadcrumbLoading = false;
    this.setDate();
    if (this.voteId) {
      await this.getPortfolio(this.voteId);
    }
  },

  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      putPortfolioTracker: "capitals/putPortfolioTracker",
      patchPortfolioTracker: "capitals/patchPortfolioTracker",
      getPortfolioTrackerId: "capitals/getPortfolioTrackerId"
    }),

    async getPortfolio(voteId) {
      try {
        const response = await this.getPortfolioTrackerId(voteId);
        this.monthlyProfitPercents = response?.monthlyProfitPercents;
        this.cagrPercents = response?.cagrPercents;
        this.totalProfitPercents = response?.totalProfitPercents;
        this.dailyProfitPercents = response?.dailyProfitPercents;
        this.balance = response?.balance;
        this.profit = response?.profit;
        this.drawdownPercents = response?.drawdownPercents;
        this.sharpCoef = response?.sharpCoef;
        this.roi = response?.roi;
        this.maxMonthlyProfitPercents = response?.maxMonthlyProfitPercents;
        this.maxMonthlyDrawdownPercents = response?.maxMonthlyDrawdownPercents;
        this.setDate(response?.createdAt);
      }
      catch (error) {
        console.log(error);
      }
    },

    async savePortfolio() {
      const params = {
        auCapital: `/api/au_capitals/${this.id}`,
        monthlyProfitPercents: this.monthlyProfitPercents,
        cagrPercents: this.cagrPercents,
        totalProfitPercents: this.totalProfitPercents,
        dailyProfitPercents: this.dailyProfitPercents,
        balance: this.balance,
        profit: this.profit,
        drawdownPercents: this.drawdownPercents,
        sharpCoef: this.sharpCoef,
        createdAt: this.date,
        roi: this.roi,
        maxMonthlyProfitPercents: this.maxMonthlyProfitPercents,
        maxMonthlyDrawdownPercents: this.maxMonthlyDrawdownPercents
      };
      try {
        const response = await this.putPortfolioTracker(params);
        if (response?.code == 267) {
          this.showError(response?.message || this.$t("toasts.error.portfolioSend"));
        }
        else {
          this.showSuccess(this.$t("toasts.success.portfolioSend"));
          this.$router.push({ name: "portfolio tracker", params: { id: this.id } });
        }
      }
      catch (error) {
        this.showError(this.$t("toasts.error.portfolioSend"));
      }
    },

    async editPortfolio() {
      const params = {
        auCapital: `/api/au_capitals/${this.id}`,
        monthlyProfitPercents: this.monthlyProfitPercents,
        cagrPercents: this.cagrPercents,
        totalProfitPercents: this.totalProfitPercents,
        dailyProfitPercents: this.dailyProfitPercents,
        balance: this.balance,
        profit: this.profit,
        drawdownPercents: this.drawdownPercents,
        sharpCoef: this.sharpCoef,
        createdAt: this.date,
        roi: this.roi,
        maxMonthlyProfitPercents: this.maxMonthlyProfitPercents,
        maxMonthlyDrawdownPercents: this.maxMonthlyDrawdownPercents
      };
      try {
        await this.patchPortfolioTracker({ id: this.voteId, body: params });
        this.showSuccess(this.$t("toasts.success.portfolioSend"));
        this.$router.push({ name: "portfolio tracker", params: { id: this.id } });
      }
      catch (error) {
        this.showError(this.$t("toasts.error.portfolioSend"));
      }
    },

    goBack() {
      this.$router.push({ name: "portfolio tracker", params: { id: this.id } });
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    },

    setDate(value) {
      if (!value) {
        this.date = new Date();
      }
      else {
        this.date = new Date(value);
      }
    },

    formatView(date) {
      return moment(date).locale("ru").format("L");
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px;
}

.header {
  margin-bottom: 20px;
  .title {
    font-size: 24px;
    color: var(--text-color);
    line-height: 0.9;
  }
  .explain {
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    .data {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-60);
    }
  }
}
.top-header {
  display: flex;
  justify-content: space-between;
}
.i-line-half {
  width: 50%;
  padding-right: 8px;
}
.i-title {
  font-size: 18px;
}
.i-block {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-bottom: 20px;
}

.i-left, .i-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.i-label {
  margin-top: 14px;
  margin-bottom: 6px;
}

@media (max-width: 590px) {
  .i-block {
    flex-direction: column;
  }
  .i-line-half {
    width: 100%;
    padding-right: 0px;
  }
}
</style>
