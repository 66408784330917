<template>
  <!-- More two variable  -->
  <div class="container-fluid proposal-info">
    <div class="row proposal-info-first-line">
      <div class="col-12 col-sm-3 col-md-3">
        <span class="mobile-body">
          {{ momentDate(daoRequest.endAt).format("DD.MM.YYYY HH:mm:ss Z") }}
        </span>
      </div>
      <div class="col-12 col-sm-4 col-md-4 left-indent">
        <span class="mobile-body">
          {{ daoRequest.name }}
        </span>
      </div>
      <div class="col-12 col-sm-5 col-md-5">
        <span class="mobile-body">
          {{ daoRequest.description }}
        </span>
      </div>
    </div>
    <div class="row proposal-info-second-line">
      <div class="separator" />
      <div class="col-6">
        <div class="wrapper">
          <span>{{ $t("daoTokens.survey.results") }} %</span>
          <div class="chart-wrapper">
            <Apexchart
              height="160px"
              class="left-panel__card-chard"
              type="donut"
              :options="options"
              :series="series"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="wrapper vote">
          <span>{{ $t("daoTokens.survey.resultsCount") }}</span>
          <div class="vote-wrapper">
            <div
              v-for="(item, index) in daoRequest.result"
              :key="index"
            >
              <span> {{ item.name }} - {{ item.count }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row proposal-info-third-line"
      :class="{'empty': daoSumResult === '0'}"
      @click="goToVotePage"
    >
      {{ $t(`daoTokens.survey.${daoSumResult === '0' ? 'noData' : 'protocol'}`) }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";

export default {
  props: {
    daoRequest: {
      type: Object,
      default: () => {},
      required: true
    }
  },

  computed: {
    daoSumResult() {
      return _.reduce(this.daoRequest?.result, (acc, el) =>
        BigNumber(el?.amount ?? 0).plus(acc).toFixed(0), "0");
    },

    series() {
      if (this.daoSumResult === "0") {
        return [1];
      }
      return _.map(this.daoRequest?.result, el =>
        BigNumber(el).multipliedBy(100).dividedBy(this.daoSumResult).toString(10));
    },
    options() {
      const ret = {
        labels: _.map(this.daoRequest?.result, el => el.name),
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: this.daoSumResult !== "0"
        },
        legend: {
          show: this.daoSumResult !== "0",
          fontSize: "11px",
          fontFamily: "Suisse Intl",
          labels: {
            colors: [this.isLightTheme ? "#080808" : "#FFFFFF"]
          },
          markers: {
            width: 12,
            height: 8
          },
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
              "%"
            ];
          }
        }
      };
      if (this.daoSumResult === "0") {
        ret["colors"] = ["#b3b3b3"];
      }
      return ret;
    },
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },

  methods: {
    momentDate(date) {
      return moment(date);
    },

    goToVotePage() {
      if (this.daoSumResult !== "0") {
        this.$router.push({ name: "voiting protocol", params: { id: this.daoRequest.id } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.proposal-info {
  &-first-line {
    padding-top: 30px;
    padding-bottom: 25px;
    font-weight: 450;
    font-size: 12px;
    border-bottom: 1px dashed var(--color-70);
    @media screen and (max-width: 576px) {
      padding-bottom: 0;
      .col-12 {
        margin-bottom: 35px;
        &:first-child {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-top: 1px solid var(--color-80);
          border-bottom: 1px solid var(--color-80);
          .mobile-header {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }
  &-second-line {
    padding-top: 20px;
    padding-bottom: 40px;
    position: relative;
    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      border-bottom: none;
    }
    .separator {
      position: absolute;
      height: 50px;
      width: 0;
      border-right: 0.01px dashed var(--color-70);
      padding: 0;
      left: 55%;
      top: 0;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      @media screen and (max-width: 576px) {
        align-items: flex-start;
      }
      &.vote {
        @media screen and (max-width: 576px) {
          margin-top: 30px;
          row-gap: 20px;
        }
      }
      span {
        font-weight: 450;
        font-size: 12px;
      }
    }
  }
  &-third-line {
    border-bottom: 1px dashed var(--color-40);
    cursor: pointer;
    padding: 10px;
    color: #2ABA7C;
  }
}
.mobile-header {
  font-size: 12px;
  color: var(--color-60);
  font-weight: 450;
  display: none;
  margin-bottom: 8px;
  @media screen and (max-width: 576px) {
    display: flex;
  }
}

.mobile-body {
  font-size: 14px;
}
.chart-wrapper {
  width: 350px;
  position: relative;
  @media screen and (max-width: 576px) {
    margin-left: -120px;
  }
}
.col,
.col-3,
.col-4,
.col-5,
.col-7 {
  padding: 0;
}

.row > * {
  padding-left: 0;
  padding-right: 0;
}
input {
  display: none;
}
.vote-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.left-indent {
  padding-right: 39px;
}
.empty {
  cursor: default;
  color: var(--color-50);
}
</style>
