<template>
  <div />
</template>

<script >
import { mapGetters } from "vuex";

export default {
  name: "BuyAut",
  computed: {
    ...mapGetters({
      language: "language"
    })
  },

  mounted() {
    const url = this.language === "en" ? "https://aut.finance/en?pay=1" : "https://aut.finance/ru?pay=1";
    window.open(url, "_blank");
    this.$router.go(-1);
  }
};
</script>

<style scoped lang="scss">

</style>