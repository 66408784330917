<template>
  <div>
    <div
      v-if="headerLine"
      class="header-line"
    >
      <div class="fs22">
        {{ $t("myOrders.title") }}
      </div>
      <RouterLink
        :to="{ path: '/briefcase', query: { selectedTitle: $t('briefcase.ordersTabLabel') }}"
      >
        {{ $t('briefcase.allOrders') }}
      </RouterLink>
    </div>

    <div class="content-line">
      <AuTable
        v-if="orderLoading || itemsOrders.length > 0"
        class="briefcase-table briefcase-table-hiddable"
        :columns="columnsOrders"
        :items="itemsOrders"
        :loading="orderLoading"
        :property-style-function="() => ({ padding: '12px' })"
        :width-first="'auto'"
        :width-colum="'230px'"
      >
        <template #project="{ value }">
          <div
            class="link link-container"
            @click="toLinkProject(value.id)"
          >
            <img
              class="t-image"
              :src="value.icon"
              alt="cryptoactive"
              @click="toLinkProject(value.id)"
            >
            <div class="block-name">
              <div class="link-text">
                {{ value.name }}
              </div>
              <div class="explain uppercase">
                {{ value.type }}
              </div>
            </div>
          </div>
        </template>
        <template #sum="{ value }">
          <AuDecimalStatic
            :value="value.value"
            :decimal="value.decimals"
            :indent="value.indent"
            :show-addition="false"
          /><span>{{ value.mnemonicEng }}</span>
        </template>
        <template #status="{value}">
          <div class="d-flex justify-content-center">
            <div class="status">
              <div v-if="value.isDraft">
                {{ value.statusName }}
              </div>
              <div v-if="!value.isCompleted && value.value">
                <div class="progress_line">
                  <div>
                    {{ value.statusProject }}
                  </div>
                  <ProgressBar
                    class="indent_progress"
                    :procent="value.procent"
                    :status="value.statusProject"
                  />

                  <div class="progress_text">
                    <AuDecimalStatic
                      :value="value.value"
                      :addition="value.addition"
                      :decimal="value.decimal"
                      :indent="value.indent"
                    />
                    <div class="progress_text">
                      {{ value.procent + "%" }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!value.isDraft && !value.value">
                {{ $t('projects.status.inProcess') }}
              </div>
            </div>
          </div>
        </template>
      </AuTable>
      <div
        v-else
        class="order-empty"
      >
        <img
          class="image"
          src="/img/dashboard/not_found_order.svg"
          alt=""
        >
      </div>
    </div>

    <!-- for mobile -->
    <div class="briefcase-accordion">
      <div v-if="itemsOrders != 0">
        <AuAccordion
          v-for="(order, index) in itemsOrders"
          :key="order"
          :last-child="(index == itemsOrders.length - 1)"
        >
          <template #title>
            <div
              class="link link-container"
              @click="toLinkProject(order.project.id)"
            >
              <img
                class="t-image"
                :src="order.project.icon"
                alt="cryptoactive"
                @click="toLinkProject(order.project.id)"
              >
              <div class="block-name">
                <div class="link-text">
                  {{ order.project.name }}
                </div>
                <div class="explain uppercase">
                  {{ order.project.type }}
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div class="company-info container-fluid">
              <div
                v-for="(column, cindex) in columnsOrdersAccordion"
                :key="cindex"
                class="row"
              >
                <div class="col company-info-title">
                  <span>
                    {{ column.name }}
                  </span>
                </div>
                <div
                  class="col"
                >
                  <div v-if="column.name == $t('briefcase.invested')">
                    <AuDecimalStatic
                      v-if="column.decimal"
                      :value="order[column.property].value"
                      :decimal="order[column.property].decimals"
                      :show-addition="false"
                    />
                    <span>
                      {{ order[column.property].mnemonicEng }}
                    </span>
                  </div>

                  <div v-if="column.name == $t('briefcase.statusColumn')">
                    <div class="d-flex justify-content-end">
                      <div class="col status">
                        <div v-if="order.status?.isDraft">
                          {{ order.statusName }}
                        </div>
                        <div v-if="!order.status?.isCompleted && order.status?.value">
                          <div class="progress_line">
                            <div>
                              {{ order.status?.statusProject }}
                            </div>
                            <ProgressBar
                              class="indent_progress"
                              :procent="order.status?.procent"
                              :status="order.status?.statusProject"
                            />

                            <div class="progress_text">
                              <AuDecimalStatic
                                :value="order.status?.value"
                                :addition="order.status?.addition"
                                :decimal="order.status?.decimal"
                                :indent="order.status?.indent"
                              />
                              <div class="progress_text">
                                {{ order.status?.procent + "%" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="!order.status?.isDraft && !order.status?.value">
                          {{ $t('projects.status.inProcess') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="column.name == $t('briefcase.startOpen')">
                    <div>
                      {{ order.dateEnd }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </AuAccordion>
      </div>

      <span v-else-if="itemsOrders.length == 0">
        No orders yet
      </span>
    </div>

    <AuPagination
      v-if="ordersTotalItems > itemsPerPage && isPagination"
      :hide="orderLoading"
      :total-items="ordersTotalItems"
      @pageChanged="pageChanged"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { convertPercent, convertStatus } from "@/components/capital/capitalHelper";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProgressBar
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 30
    },
    headerLine: {
      type: Boolean,
      default: false
    },
    isPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      orderLoading: true,
      dataOrders: null,
      ordersTotalItems: null,
      pageId: 1
    };
  },

  computed: {
    ...mapGetters({
      language: "language",
      tokens: "tokens",
      dataLoading: "withdrawal/dataLoading",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    itemsOrders() {
      return _.map(this.dataOrders, item => {
        const auCapital = {
          currentCap: item?.currentCap,
          maxCap: item?.maxCap,
          minCap: item?.minCap,
          minCount: item?.minCount,
          dateStart: item?.dateStart,
          isClosed: item?.isClosed,
          isSuccess: item?.isSuccess,
          dateEnd: item?.dateEnd
        };

        return {
          project: {
            name: process.env.VUE_APP_PROJECT_TITLE_TYPE == "name" ? item?.nameEng : item?.tokenBase?.mnemonicEng,
            id: item?.id,
            icon: item?.mainImage?.name || "/svg/light/grid_round_green.svg",
            type: this.prepareTransactionNotes(item?.projectType)
          },
          sum: {
            value: item?.sum,
            decimals: item?.tokenQuote?.decimals,
            indent: item?.tokenQuote?.indent,
            mnemonicEng: item?.tokenQuote?.mnemonicEng
          },
          status: {
            statusName: item?.isDraft ? this.$t("projects.status.draft") :
              (item?.isCompleted ? this.$t("projects.status.approved") : this.$t("projects.status.inProcess")),
            isDraft: item?.isDraft,
            isCompleted: item?.isCompleted,
            value: auCapital?.currentCap,
            decimal: item?.tokenQuote?.decimals,
            indent: item?.tokenQuote?.indent,
            addition: item?.tokenQuote?.mnemonicEng,
            procent: convertPercent(auCapital),
            statusProject: convertStatus(auCapital)
          },
          dateEnd: this.formatDateRange(item?.dateStart, item?.dateEnd)
        };
      });
    },

    columnsOrders() {
      return [{ name: process.env.VUE_APP_PROJECT_TITLE_TYPE == "name" ? this.$t("briefcase.projectName") : this.$t("briefcase.projectSymbol"), align: "start", slot: "project", property: "project" },
        ...this.columnsOrdersAccordion];
    },

    columnsOrdersAccordion() {
      return [
        { name: `${this.$t("briefcase.invested")}`, align: "center", slot: "sum", property: "sum", decimal: true },
        { name: `${this.$t("briefcase.statusColumn")}`, align: "center", slot: "status", property: "status", decimal: true },
        { name: this.$t("briefcase.startOpen"), align: "center", slot: "dateEnd", property: "dateEnd" }
      ];
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },

  async mounted() {
    this.defaultToken = _.find(this.tokens, token => token.id == this.defaultTokenId);
    await this.getOrdersPage();
  },

  methods: {
    ...mapActions({
      getInvestmentsAuCapitals: "capitals/getInvestmentsAuCapitals"
    }),

    toLinkProject(link) {
      this.$router.push({ name: "project card", params: { id: link } });
    },

    toLink(link) {
      this.$router.push({ name: "token info", params: { id: link } });
    },

    async getOrdersPage() {
      this.orderLoading = true;
      try {
        let resp = await this.getInvestmentsAuCapitals({
          pageId: this.pageId,
          itemsPerPage: this.itemsPerPage,
          status: "open"
        });

        this.dataOrders = resp.data;
        this.ordersTotalItems = resp.totalItems;
      }
      catch (e) {
        console.log("error", e);
      }
      finally {
        this.orderLoading = false;
      }
    },
    async pageChanged(actualPage) {
      this.pageId = actualPage;
      await this.getOrdersPage();
    },

    prepareTransactionNotes(projectType) {
      switch (projectType) {
        case "voting":
          return this.$t("projects.status.voting");
        case "invest":
          return this.$t("projects.status.investment");
        default:
          return projectType;
      }
    },

    formatDateRange(startDate, endDate) {
      const formatMonth = (date) => {
        if (this.language === "ru") {
          return moment(date)
            .locale("ru")
            .format("DD MMM YYYY")
            .replace(/\./, "")
            .replace(/[а-я]+/g, (month) => month.charAt(0).toUpperCase() + month.slice(1));
        }
        return moment(date).locale("en").format("DD MMM YYYY");
      };

      return formatMonth(startDate) + " - " + formatMonth(endDate);
    }
  }
};
</script>


<style scoped lang="scss">
.header-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    padding-top: 4px;
    font-size: 13px;
    color: #279063;
    text-decoration: none;
  }
}
.briefcase {
  &-info {
    padding: 11px 20px 0 18px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
      margin-bottom: 30px;
    }
  }

  &-table {
    margin-top: 10px;
    margin-bottom: 10px;

    &-hiddable {
      @media screen and (max-width:590px) {
        display: none;
      }
    }
  }

  &-accordion {
    margin-top: 10px;
    display: none;

    @media screen and (max-width:590px) {
      display: block;
    }
  }
}

.spot-balance {
  color: var(--color-50);
  font-weight: 500;
  font-size: 12px;
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }

  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;

    &-title {
      color: var(--color-60)
    }

    .row {
      font-weight: 450;
      font-size: 12px;

      .col:last-child {
        text-align: end;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

}

.icon-with-bg {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  opacity: var(--opacity);
}

.link-container {
  display: flex;
  align-items: center;
}

.link {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  .block-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .link-text {
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--color-00);
        text-decoration-style: dashed;
        text-underline-offset: 4px;
      }
    }
    .explain {
      font-weight: 450;
      font-size: 12px;
      color: var(--color-60);
      @media screen and (max-width:384px) {
        line-height: 1.1;
      }
    }
  }
}
.briefcase-tooltip {
  margin-left: 2px;
}
.t-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
}
.progress_line {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.progress_text {
  display: flex;
  justify-content: space-between;
  font-weight: 450;
  font-size: 12px;
  color: var(--color-20);
}
.indent_progress {
  margin-top: 6px;
  width: 100%;
}
.status {
  width: 135px;
}
.uppercase::first-letter {
  text-transform: uppercase;
}
.order-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 400px;
    height: 300px;
  }
}
</style>

