<template>
  <div>
    <div
      v-if="headerLine"
      class="header-line"
    >
      <div class="title">
        {{ $t("dashboard.titles.project") }}
      </div>
    </div>
    <div
      v-if="!loading"
      class="progress-line"
    />

    <div v-if="formattedProjects?.length">
      <AuTable
        class="autable-table"
        :columns="columns"
        :items="formattedProjects"
        :icons="null"
        :loading="loading"
      >
        <template #name="{ value }">
          <div>
            {{ value.name }}
          </div>
          <div class="explain uppercase">
            {{ value.type }}
          </div>
        </template>
        <template #expertVotes="{ value }">
          <div class="d-flex justify-content-center">
            {{ value.volume }}
          </div>
        </template>
        <template #minExpertVotes="{ value }">
          <div class="d-flex justify-content-center">
            {{ value.volume }}
          </div>
        </template>
        <template #expertApprovals="{ value }">
          <div class="d-flex justify-content-center">
            {{ value.expertVotes }}&nbsp;&#47;&nbsp;{{ value.minExpertVotes }}
          </div>
        </template>
        <template #action="{ value }">
          <div
            v-click-outside="closeAllMenus"
            class="container-menu"
          >
            <AuIcon
              icon="more"
              as-button
              @click="openMenu(value.index)"
            />
            <div
              v-if="showMenu[value.index]"
              class="context-menu"
            >
              <AuButton
                v-if="true"
                width="100%"
                center
                popup
                border-color="none"
                active-bg-color="none"
                hovered-bg-color="none"
                @click="voteProject(value)"
              >
                {{ $t("projects.vote") }}
              </AuButton>
            </div>
          </div>
        </template>
      </AuTable>
      <div class="autable-accordion">
        <AuAccordion
          v-for="(project, index) in formattedProjects"
          :key="project"
          :last-child="index == formattedProjects.length - 1"
        >
          <template #title>
            <div>
              {{ project.projectName.name }}
            </div>
            <div class="explain uppercase">
              {{ project.projectName.type }}
            </div>
          </template>
          <template #content>
            <div class="content-info">
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("projects.expertVotes") }}</span>
                </div>
                <div class="col">
                  <div>
                    {{ project.expertVotes.volume }}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("projects.minExpertVotes") }}</span>
                </div>
                <div class="col">
                  <div>
                    {{ project.expertVotes.volume }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("projects.expertApprovals") }}</span>
                </div>
                <div class="col">
                  <span>{{ project.expertApprovals.expertVotes }}</span>
                  &nbsp;&#47;&nbsp;
                  <span>{{ project.expertApprovals.minExpertVotes }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col buttons">
                  <AuButton
                    v-if="true"
                    bordered
                    center
                    width="80px"
                    @click="voteProject(value)"
                  >
                    {{ $t("projects.vote") }}
                  </AuButton>
                </div>
              </div>
            </div>
          </template>
        </AuAccordion>
      </div>
      <div
        v-if="!loading"
        class="table-line"
      />
    </div>
    <div
      v-if="!loading && !formattedProjects?.length"
      class="empty"
    >
      <img
        class="image"
        src="/img/dashboard/not_found_voting.svg"
        alt=""
      >
    </div>
    <AuPagination
      v-if="totalItems > 30"
      :hide="loading"
      :total-items="totalItems"
      @pageChanged="getPagePagination"
    />
    <AuDialogModal
      v-model="showDialogModal"
      @confirm="sendVote"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: {
    itemsPerPage: {
      type: Number,
      default: 30
    },
    headerLine: {
      type: Boolean,
      default: false
    },
    isPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      showDialogModal: false,
      selectedItem: {},
      dataItems: null,
      showMenu: [],
      auCapitals: [{}],
      page: 1,
      totalItems: 100,
      columns: [
        {
          name: this.$t("projects.firstColumn"),
          slot: "name",
          property: "projectName",
          align: "start",
          width: "100px"
        },
        {
          name: this.$t("projects.expertVotes"),
          align: "center",
          slot: "expertVotes"
        },
        {
          name: this.$t("projects.minExpertVotes"),
          align: "center",
          slot: "minExpertVotes"
        },
        {
          name: this.$t("projects.expertApprovals"),
          align: "center",
          slot: "expertApprovals"
        },
        {
          name: this.$t("projects.sixthColumn"),
          align: "center",
          slot: "action"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      language: "language"
    }),

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    formattedProjects() {
      return _.map(this.auCapitals, (auCapital, i) => ({
        projectName: {
          name: auCapital.nameEng || this.$t("projects.noName"),
          type: this.prepareTransactionNotes(auCapital.projectType)
        },
        expertVotes: {
          volume: auCapital.expertVotes || 0
        },
        minExpertVotes: {
          volume: auCapital.minExpertVotes || 0
        },
        expertApprovals: {
          expertVotes: auCapital.expertVotes || 0,
          minExpertVotes: auCapital.minExpertVotes || 0
        },
        action: {
          index: i,
          type: auCapital.projectType,
          capitalId: auCapital?.id
        }
      }));
    }
  },

  async mounted() {
    this.loading = true;
    await this.getAuCapitals();
    this.closeAllMenus();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getAuCapitalsParams: "capitals/getAuCapitalsParams",
      sendTokenEvents: "sendTokenEvents"
    }),

    async getAuCapitals() {
      const { data, totalItems } = await this.getAuCapitalsParams({
        page: this.page,
        itemsPerPage: 30,
        status: "wating",
        isMinExpertVotesNotZero: true
      });
      this.auCapitals = data;
      this.totalItems = totalItems;
    },

    async sendVote() {
      const params = {
        type: "token_expert_vote",
        auCapital: `/api/au_capitals/${this.selectedItem.capitalId}`,
        description: "test vote",
        isTask: true,
        isConfirmed: true
      };
      try {
        const res = await this.sendTokenEvents(params);
        if (res?.status == "267") {
          this.$toast.error(this.$t("projects.alreadyVote"));
          this.showDialogModal = false;
        }
        else {
          this.$toast.success(this.$t("projects.successVote"));
          this.showDialogModal = false;
        }
      }
      catch (e) {
        this.$toast.error(this.$t("projects.errorVote"));
        this.showDialogModal = false;
      }
    },

    voteProject(value) {
      this.selectedItem = value;
      this.showDialogModal = true;
    },

    prepareTransactionNotes(projectType) {
      switch (projectType) {
        case "voting":
          return this.$t("projects.status.voting");
        case "invest":
          return this.$t("projects.status.investment");
        default:
          return projectType;
      }
    },

    closeAllMenus() {
      this.showMenu = _.map(this.requestsToIssue, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    async getPagePagination(actualPage) {
      this.loading = true;
      this.page = actualPage;
      await this.getAuCapitals();
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/table.scss";

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 400px;
    height: 300px;
    @media screen and (max-width: 670px) {
      width: 280px;
      height: 200px;
    }
    @media screen and (max-width: 384px) {
      width: 180px;
      height: 140px;
    }
  }
}
</style>
