<template>
  <div>
    <AuBreadcrumb :parent-name="$t('router.pages.dashboard')" />
    <div class="card referral-panel">
      <div class="title">
        {{ $t("myReferrals.title") }}
      </div>
      <div class="count">
        {{ $t("myReferrals.number") }}
        <span class="value">
          {{ totalItems }}
        </span>
      </div>
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-else>
        <AuTable
          :columns="columns"
          :items="items"
          width-first="10%"
        />
        <AuPagination
          v-if="totalItems > itemsPerPage"
          :total-items="totalItems"
          @pageChanged="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions } from "vuex";

export default {
  name: "Referrals",
  components: { ProgressLoader },

  data() {
    return {
      loading: true,
      referral: [],
      page: 1,
      itemsPerPage: 30,
      totalItems: 0
    };
  },

  computed: {
    columns() {
      return [{ name: this.$t("myReferrals.registered"), align: "left", padding: "7px" },
        { name: this.$t("referral.email"), align: "left", padding: "7px" },
        { name: this.$t("myReferrals.level"), align: "left", padding: "7px" }
      ];
    },

    items() {
      return _.map(this.referral, item => ({
        date: moment(item?.createdAt).format("DD.MM.YYYY"),
        email: item?.email,
        level: item?.level ?? "-"
      }));
    }
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    ...mapActions({
      getReferrals: "getReferrals"
    }),

    async changePage(actualPage) {
      this.page = actualPage;
      await this.getData();
    },

    async getData() {
      this.loading = true;
      try {
        const resp = await this.getReferrals({
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          pagination: true
        });
        this.referral = resp.data;
        this.totalItems = resp.totalItems;
      }
      catch (e) {
        console.error(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.referral-panel {
  padding: 20px;

  .title {
    font-size: 24px;
    line-height: 1.1em;
  }

  .count {
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    margin-bottom: 20px;
  }

  .value {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-60);
  }
}
</style>