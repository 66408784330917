<template>
  <div>
    <div
      class="top-tags"
      :style="style"
    >
      <div
        v-if="status"
        class="time"
      >
        <AuIcon :icon="icon" /> {{ status }}
      </div>
      <div class="tag">
        {{ tag }}
      </div>
    </div>
    <div class="bottom-tags">
      <img
        v-if="isAutenticProject"
        class="aut-tag"
        src="/img/capital/autentic.svg"
      >
      <div v-else />

      <div
        v-if="isVoting"
        class="vote bottom-tag"
      >
        <AuIcon icon="vote" /> Voting
      </div>
      <div
        v-else-if="type"
        class="type-tag bottom-tag"
      >
        <img
          :src="`/img/capital/${type}.png`"
        >
        {{ typeName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVoting: {
      type: Boolean,
      default: false
    },
    isAutenticProject: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    typeName: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  },

  computed: {
    style() {
      return {
        "--status-color": this.color
      };
    }
  }
};
</script>

<style lang="scss" scoped>

.bottom-tags {
  display: flex;
  justify-content: space-between;

  position: absolute;
  bottom: 11px;

  width: 100%;

  margin-left: 11px;
  padding-right: 22px;

  z-index: 1000;

  .type-tag {
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    color: black;

    display: flex;
    align-items: center;
    gap: 5px;

    white-space: nowrap;
  }
}

.top-tags {
  position: absolute;
  top: 0px;

  display: flex;
  justify-content: flex-end;
  width: 100%;

  margin-top: 11px;
  padding-right: 11px;
  gap: 11px;

  z-index: 1000;

  >div {
    border-radius: 10px;
    padding: 8px 10px;
    font-weight: 450;
    font-size: 12px;
  }
}

.time
{
  background: var(--status-color);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 8px;
}

.vote
{
  background: white;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 90px;
  height: 38px;
}

.tag
{
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  color: black;
}

.bottom-tag {
  border-radius: 10px;
  padding: 6px 10px;
  font-weight: 450;
  font-size: 12px;
}
</style>