<template>
  <div
    class="indent_progress"
    :isLightTheme="isLightTheme"
    :style="cssVars"
  >
    <div
      class="progress-bar progress-bar_first"
      :style="`${cssVars}; width: ${procent}%`"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    procent: {
      type: [Number, String],
      default: 0
    },
    status: {
      type: String,
      default: ""
    },
    green: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    cssVars() {
      return {
        "--progress-color-main": this.status == this.$t("projects.status.waiting") || this.status == this.$t("projects.status.open") || this.green ? "#28E593" : "#d8dad9",
        "--progress-color-bg": this.status == this.$t("projects.status.waiting") || this.status == this.$t("projects.status.open") || this.green ? "#28E59340" : "#eff1f0"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-bar {
  height: 10px;
  background-color: var(--progress-color-main);
  border-radius: 2px;

  position: absolute;
}

.indent_progress {
  height: 10px;
  border-radius: 2px;

  position: relative;

  &[isLightTheme=true] {
    background-color: var(--progress-color-bg);
  }

  &[isLightTheme=false] {
    background-color: var(--progress-color-bg);
  }
}
</style>