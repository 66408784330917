<template>
  <div>
    <div class="r-title">
      <span class="fs22">{{ $t("referral.title") }}</span>
      <RouterLink
        to="/referrals"
        class="r-link"
      >
        {{ $t("referral.link") }}
      </RouterLink>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="r-full-size"
    >
      <div
        v-if="referral.length > 0"
        class="r-team"
      >
        <div class="info">
          <img
            class="icon"
            src="/img/dashboard/in-command.svg"
            alt=""
          >
          <span>
            {{ $t("referral.inTeam") }}
            <span class="count"> {{ totalItems }} </span>
          </span>
        </div>
        <div class="info">
          <img
            class="icon"
            src="/img/dashboard/dollar.svg"
            alt=""
          >
          <span>
            {{ $t("referral.total") }}
            <span class="count">
              {{ investmentSum }}
            </span>
          </span>
        </div>
      </div>
      <div v-if="referral.length > 0">
        <AuTable
          :columns="columns"
          :items="items"
          :property-style-function="propertyStyleFunction"
        />
      </div>
      <div
        v-else
        class="r-empty"
      >
        <img
          class="r-image"
          src="/img/dashboard/not_found_ref.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Referral",
  components: { ProgressLoader },
  data() {
    return {
      referral: [],
      page: 1,
      itemsPerPage: 3,
      totalItems: 0,
      investmentSum: "-",
      loading: true,
      currencySup: new CurrencySupport()
    };
  },
  computed: {
    ...mapGetters({
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultAutMnemonic: "defaultToken/defaultAutMnemonic",
      tokens: "tokens",
      tokensUtility: "tokensUtility"
    }),

    defaultAutToken() {
      return _.find(this.tokensUtility, token => token.mnemonicEng === this.defaultAutMnemonic);
    },

    defaultAutTokenId() {
      return this.defaultAutToken?.id;
    },

    columns() {
      return [{ name: this.$t("referral.date"), align: "left", padding: "7px" },
        { name: this.$t("referral.email"), align: "left", padding: "7px" }];
    },

    items() {
      return _.map(this.referral, item => ({
        date: moment(item?.createdAt).format("DD.MM.YYYY"),
        email: item?.email
      }));
    }

  },
  async mounted() {
    await this.getReferralData();
  },
  methods: {
    ...mapActions({
      getReferrals: "getReferrals",
      getReferralInvestment: "capitals/getReferralInvestment"
    }),

    async getReferralData() {
      this.loading = true;
      try {
        const resp = await this.getReferrals({
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          pagination: true
        });
        this.referral = resp.data;
        this.totalItems = resp.totalItems;
        const usdResp = await this.getReferralInvestment(this.defaultTokenId);
        const autResp = await this.getReferralInvestment(this.defaultAutTokenId);
        this.calcInvestSum(usdResp?.sum, autResp?.sum);
      }
      catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    calcInvestSum(usdAmount, autAmount) {
      const course = this.currencySup.getCurrency(this.defaultAutTokenId, this.defaultTokenMnemonic);
      const buf = BigNumber(usdAmount).plus(BigNumber(autAmount).multipliedBy(course));
      this.investmentSum = buf.isZero() || buf.isNaN() ? "-" : `${buf.toFixed(2)} ${this.defaultTokenMnemonic}`;
    },

    propertyStyleFunction() {
      return {
        "padding": "7px"
      };
    }
  }
};
</script>

<style scoped lang="scss">
.r-title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.r-link {
  color: #279063;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}
.r-full-size {
  height: 100%;
}
.r-empty {
  display: flex;
  height: content-box;
  justify-content: center;
  align-items: center;
}
.r-image {
  width: 180px;
  height: 194px;
}
.r-team {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;

  .info {
    display: flex;
    align-items: end;
    gap: 8px;
  }

  .icon {
    width: 26px;
    height: 26px;
  }

  .count {
    font-weight: 500;
    color: #2ABA7C;
  }
}
</style>