<template>
  <span
    v-if="showValue"
    class="decimals trim"
  >{{ displayValue }}<span
    v-if="visualIndent"
    class="visual-indent"
  /><template v-if="showAddition">
    <span
      v-if="checkCurrency"
      class="mnemonic"
    >{{ checkCurrency }}</span>
    <span
      v-else
      class="no-currency"
    >NTN<AuTooltip description="NO TOKEN NAME" /></span>
  </template>
  </span>
  <span v-else>-</span>
</template>

<script>
import BigNumber from "bignumber.js";
export default {
  name: "AuDecimalStatic",

  props: {
    value: {
      type: [String, Number, BigNumber],
      default: ""
    },

    decimal: {
      type: Number,
      default: 0
    },

    indent: {
      type: Number,
      default: 2
    },

    //Валюта
    addition: {
      type: String,
      default: ""
    },

    showAddition: {
      type: Boolean,
      default: true
    },

    visualIndent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  computed: {
    showValue() {
      const value = this.value ? this.value.toString().replace(",", ".") : 0;
      return !BigNumber(value).isNaN();
    },

    displayValue() {
      let value = this.formatedValue;
      if (this.indent && value && value.includes(".")) {
        value = Number(value).toLocaleString("ru", {
          minimumFractionDigits: this.indent,
          maximumFractionDigits: this.indent,
          roundingMode: "trunc"
        });
      }
      else {
        value = Number(value).toLocaleString("ru", {
          maximumFractionDigits: this.decimal,
          roundingMode: "trunc"
        });
      }
      value = value.replace(",", ".");
      if (this.windowWidth < 490 && this.windowWidth >= 400 && value.length > 11) {
        return value.slice(0, 11) + "...";
      }
      if (this.windowWidth < 400 && value.length > 6) {
        return value.slice(0, 6) + "...";
      }
      return value;
    },

    checkCurrency() {
      if (this.addition == "-") {
        return null;
      }
      else {
        return this.addition;
      }
    },

    formatedValue() {
      const type = typeof this.value;
      let value = this.value ? this.value.toString().replace(",", ".") : 0;

      let result = "";

      if (this.decimal == 0) {
        return value;
      }

      if (type == "number") {
        result = BigNumber(value).toFixed(this.decimal);
      }
      else {
        result = BigNumber(value).dividedBy(BigNumber(10).pow(this.decimal)).toFixed(this.decimal);
      }

      return BigNumber(result).toString();
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};

</script>

<style scoped lang="scss">
.no-currency {
  color: grey;
}
.visual-indent {
  margin-right: 4px;
}
.trim {
  @media screen and (max-width: 490px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
}
</style>