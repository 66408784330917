import store from "@/store/profile/module";

export default function isUserRole() {
  const roles = Object.values(store.state.profile.roles);

  const hasExpert = roles.includes("ROLE_EXPERT");
  const hasIssuer = roles.includes("ROLE_ISSUER");
  const hasUser = roles.includes("ROLE_USER");

  if (hasExpert) {
    return "expert";
  }
  if (hasIssuer) {
    return "issuer";
  }
  if (hasUser) {
    return "user";
  }

  return null;
}


// логика не трогает роль TEST и ADMIN
// все наши роли
// ROLE_EXPERT
// ROLE_ISSUER
// ROLE_USER
// ROLE_TEST
// ROLE_ADMIN
// ZOZOZO
