<template>
  <div
    class="wysiwyg"
  >
    <div
      class="editor"
      :class="{ 'required-error': reqError }"
    >
      <div class="toolbar">
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-bold"
          @click="execCommand('bold')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-italic"
          @click="execCommand('italic')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-left"
          @click="execCommand('justifyLeft')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-center"
          @click="execCommand('justifyCenter')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-right"
          @click="execCommand('justifyRight')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-list"
          @click="execCommand('insertUnorderedList')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-marks"
          @click="execCommand('formatBlock', 'blockquote')"
        />
        <AuButton
          border-radius="7"
          center
          width="34px"
          height="34px"
          icon="wys-clear"
          @click="clearFormatting"
        />
      </div>
      <div
        ref="editor"
        class="editor-content viewer"
        :class="{ empty: isEditorEmpty }"
        contenteditable="true"
        :data-placeholder="placeholder"
        @input="updateContent"
      />

      <div
        class="viewer"
        v-html="modelValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEditorEmpty: true
    };
  },
  computed: {
    reqError() {
      return this.required ? this.isEditorEmpty : false;
    }
  },
  watch: {
    modelValue(newValue) {
      this.isEditorEmpty = !newValue.trim();
    }
  },
  mounted() {
    this.clearFormatting();
    this.$refs.editor.innerHTML = this.modelValue;
    this.isEditorEmpty = !this.modelValue.trim();
  },
  methods: {
    execCommand(command, value = null) {
      if (command === "formatBlock" && value === "blockquote") {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const selectedNode = range.commonAncestorContainer;

        let blockquoteParent = selectedNode.parentElement.closest("blockquote");
        if (blockquoteParent) {
          while (blockquoteParent.firstChild) {
            blockquoteParent.parentNode.insertBefore(
              blockquoteParent.firstChild,
              blockquoteParent
            );
          }
          blockquoteParent.remove();
        }
        else {
          document.execCommand(command, false, value);
        }
      }
      else {
        document.execCommand(command, false, value);
      }
      this.updateContent();
    },
    clearFormatting() {
      this.$refs.editor.innerHTML = "";
      this.updateContent();
    },
    updateContent() {
      let content = this.$refs.editor.innerHTML.trim();
      if (
        content == "<br>" ||
        content == "&nbsp;" ||
        content.startsWith("<div><br>")
      ) {
        this.clearFormatting();
        content = "";
      }
      this.isEditorEmpty = !content.trim()?.length;
      this.$emit("update:modelValue", content);
    }
  }
};
</script>

<style scoped lang="scss">
.wysiwyg {
  .editor {
    border: 1px solid var(--border-color);
    border-radius: 14px;
    padding: 10px;
    width: 100%;
  }
  .toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 10px;
  }

  .editor-content {
    min-height: 150px;
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 14px;
    font-family: "Suisse Intl";
    font-size: 14px;
    position: relative;
  }
  .editor-content:focus-visible {
    outline: none;
    border: 1px solid var(--color-80);
  }
  .required-error {
    border-color: #ff000069;
  }
  .viewer {
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 14px;
    min-height: 150px;
    margin-top: 10px;
    font-family: "Suisse Intl";
    font-size: 14px;
  }

  .editor-content.empty::before {
    content: attr(data-placeholder);
    position: absolute;
    top: 14px;
    left: 14px;
    color: var(--color-50);
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.wysiwyg {
  .viewer blockquote {
    margin: 10px 0;
    padding-left: 10px;
    color: var(--color-50);
    font-style: italic;

    position: relative;
  }

  .viewer blockquote::before {
    content: "\201C";
    font-size: 24px;
    font-weight: bold;
    color: var(--color-50);
    position: absolute;
    left: -8px;
    top: -8px;
  }
}
</style>
