<template>
  <div>
    <div class="o-header">
      <div class="title">
        <img
          src="/img/capital/lines.svg"
          alt=""
        >
        <span class="fs16">{{ $t(`auCapitals.portfolioExplorer.title`) }}</span>
      </div>
      <RouterLink
        class="t-link"
        :to="`/project-card/portfolio-explorer/${id}`"
      >
        {{ $t(`auCapitals.portfolioExplorer.allPortfolio`) }}
      </RouterLink>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="apex-portfolio-mini"
    >
      <Apexchart
        v-if="itemsPortfolio > 0"
        class="apex-graphic-mini"
        :series="series"
        :options="options"
      />
      <div v-else>
        <AuNoData
          class="no_data mt-3"
          :text="$t('auCapitals.portfolioExplorer.portfolioNoData')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Offers",
  components: { ProgressLoader },
  props: {
    id: {
      type: Number,
      default: 0
    },
    projectType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      page: 1,
      itemsPerPage: 5,
      itemsPortfolio: 0,
      series: [{
        data: [],
        color: "#28E593",
        name: this.$t("incomeChart.balance")
      },
      {
        data: [],
        color: "#00AEEF",
        name: this.$t("incomeChart.profit")
      }
      ]
    };
  },
  computed: {
    ...mapGetters({
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    options() {
      return {
        chart: {
          type: "area",
          toolbar: {
            show: false
          },
          parentHeightOffset: 0
        },
        stroke: {
          curve: "smooth"
        },
        fill: {
          type: "gradient",
          colors: ["#28E593", "#00AEEF"],
          gradient: {
            opacityFrom: 0.7,
            opacityTo: 0.1
          }
        },
        tooltip: {
          enabled: true
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: "top"
        },
        grid: {
          show: false,
          row: {
            colors: ["#28E5931A"],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: this.categories
        },
        yaxis: {
          labels: {
            formatter: (v, _) => {
              return `${v} ${this.defaultTokenMnemonic}`;
            }
          }
        }
      };
    }
  },

  async mounted() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getPortfolioTracker: "capitals/getPortfolioTracker"
    }),
    async getData() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auCapitalId: this.id
      };
      this.categories = [];
      this.balanceData = [];
      this.profitData = [];
      try {
        const resp = await this.getPortfolioTracker(params);

        for (const item of resp.data) {
          this.categories.unshift(moment(item?.createdAt).format("DD.MM.YYYY"));
          this.balanceData.unshift(item?.balance);
          this.profitData.unshift(item?.profit);
        }
        this.series[0].data = this.balanceData;
        this.series[1].data = this.profitData;
        this.itemsPortfolio = resp.data?.length;
      }
      catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.o-header {
  display: flex;
  justify-content: space-between;
  align-items: end;

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
  }

  a {
    color: #279063;
  }
}
.o-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.o-period {
  font-size: 12px;
}
</style>

<style lang="scss">
.apex-portfolio-mini {
  margin-right: -10px;
  .apx-legend-position-top {
    margin-top: 12px;
  }
}

.apex-graphic-mini {
  margin-left: -16px;
  margin-top: 2px;
}

// apexcharts-legend apexcharts-align-center apx-legend-position-top

</style>