import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import CapitalController from "@/api/capitalController";
const capitalController = new CapitalController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getAllAuCapitals({ commit }, pageId) {
    let data = await capitalController.getAllAuCapitals({ pageId });
    commit("setAuCapitals", data);
  }

  @setLoadingState
  async getInvestmentsAuCapitals(_context, params) {
    return await capitalController.getInvestmentsAuCapitals(params);
  }

  @setLoadingState
  async getAllAuCapitalsPagination(_context, params) {
    return await capitalController.getAllAuCapitals(params);
  }

  @setLoadingState
  async getAuCapitals(_context, id) {
    return await capitalController.getAuCapitals(id);
  }

  @setLoadingState
  async getPortfolioTracker({ commit }, params) {
    const resp = await capitalController.getPortfolioTracker(params);
    commit("setPortfolioTracker", resp?.data);
    commit("setPortfolioTotal", resp?.totalItems);
    return resp;
  }

  @setLoadingState
  async getPortfolioTrackerId(_context, id) {
    const resp = await capitalController.getPortfolioTrackerId(id);
    return resp;
  }

  @setLoadingState
  async putPortfolioTracker(_context, params) {
    const { data } = await capitalController.putPortfolioTracker(params);
    return data;
  }

  @setLoadingState
  async patchPortfolioTracker(_context, { id, body }) {
    const { data } = await capitalController.patchPortfolioTracker(id, body);
    return data;
  }

  @setLoadingState
  async getAuCapitalsByParams(_context, params) {
    return await capitalController.getAuCapitalsByParams(params);
  }
  @setLoadingState
  async getAuCapitalsParams(_context, params) {
    return await capitalController.getAuCapitalsParams(params);
  }

  @setLoadingState
  async getCapitalInvestor({ commit }, params) {
    const resp = await capitalController.getCapitalInvestor(params);
    commit("setInvestors", resp.data);
    commit("setInvestorsTotal", resp.totalItems);
  }

  @setLoadingState
  async getReferralInvestment(_context, tokenId) {
    return await capitalController.getReferralInvestment(tokenId);
  }

  async unsetFollowing({ dispatch }, id) {
    await capitalController.unsetFollowing("/api/au_capitals/" + id);
    dispatch("getFollowings");
  }

  async setFollowing({ dispatch }, id) {
    await capitalController.setFollowing("/api/au_capitals/" + id);
    dispatch("getFollowings");
  }

  async getFollowings({ commit }) {
    let data = await capitalController.getFollowings();
    commit("setFollowings", data);
  }

  async downloadMemorandum(_context, { name, id }) {
    return await capitalController.downloadMemorandum(name, id);
  }
}
export default (new Actions).asPlainObject();