<template>
  <WizardWrap
    v-if="showWizard"
    :texts="text"
  />
</template>

<script>
import WizardWrap from "@/components/wizard/WizardWrap.vue";
import { mapGetters } from "vuex";

export default {
  name: "KYCWizard",
  components: { WizardWrap },
  computed: {
    ...mapGetters({
      profile: "profile/profile"
    }),

    showWizard() {
      return /true/i.test(process.env.VUE_APP_ENABLE_KYC_REQUIREMENT) &&
        this.profile?.isConfirmed && this.profile?.kycStatus !== "completed";
    },

    text() {
      return [
        {
          label: { text: this.$t("KYCWizard.title") },
          link: {
            text: this.$t("KYCWizard.text"),
            link: "/kyc"
          },
          button: {
            text: this.$t("KYCWizard.button"),
            link: "/kyc"
          },
          verification: false
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss"></style>