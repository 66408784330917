<template>
  <img
    class="pc-indent ic-image "
    :src="src"
    :alt="alt"
    @load="onImageLoad"
    @error="onImageError"
  >
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: ""
    }
  },

  emits: ["click"],

  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    }
  },
  methods: {
    onImageLoad() {},
    onImageError() {}
  }
};
</script>

<style scoped lang="scss">
.ic-image {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
  object-fit: cover;
}
</style>
