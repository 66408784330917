<template>
  <div>
    <div class="o-header">
      <div class="title">
        <img
          src="/img/capital/lines.svg"
          alt=""
        >
        <span class="fs16">{{ $t(`auCapitals.offers.title${vote}`) }}</span>
      </div>
      <RouterLink
        class="t-link"
        :to="`/${isVoting?'project-card/vote-history':'capital-history'}/${id}`"
      >
        {{ $t(`auCapitals.offers.allInvestments${vote}`) }}
      </RouterLink>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <div
        v-if="transactions[0]?.transactions?.length > 0"
        class="o-content"
      >
        <LastTransactionsList
          :transactions="transactions"
          :invesrt-signs="true"
        />
      </div>
      <div v-else>
        <AuNoData
          class="no_data mt-3"
          :text="$t('auCapitals.offers.investNoData')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import LastTransactionsList from "@/components/lastTransactions/LastTransactionsList";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Offers",
  components: { LastTransactionsList, ProgressLoader },
  props: {
    id: {
      type: Number,
      default: 0
    },
    projectType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      addressEth: null,
      userIRI: "",
      transactions: [],
      currentCapital: {}
    };
  },
  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      profile: "profile/profile"
    }),

    isVoting() {
      return this.projectType === "voting";
    },

    vote() {
      return this.isVoting ? "Vote" : "";
    }
  },

  watch: {
    async profile() {
      await this.getTransactions();
    }
  },
  async mounted() {
    this.userIRI = `/api/users/${this.$auth.user().id}`;
    this.loading = true;
    this.currentCapital = await this.getAuCapitals(this.id);
    this.userIdUrl = `/api/users/${this.$auth.user().id}`;
    await this.getTransactions();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getTransactionsHistory: "getTransactionsHistory",
      getAuCapitals: "capitals/getAuCapitals"
    }),

    async getTransactions() {
      if (!this.ethAddresses[0]?.verifiedAt) {
        return;
      }

      let params = {
        page: 1,
        itemsPerPage: 3,
        auCapitalId: this.id,
        sort: "desc",
        methodName: "vote,investing,investing_nft"
      };
      let resp = await this.getTransactionsHistory(params);
      let transfer = resp.data;
      this.transactions = [{ date: "", transactions: [] }];

      if (transfer.length === 0) {
        return;
      }
      transfer.forEach((transaction) => {
        this.ethAddresses.forEach((eth) => {
          if (_.toLower(eth.value) === _.toLower(transaction?.addressFrom)) {
            this.addressEth = transaction?.addressFrom;
          }
          if (this.userIdUrl == _.toLower(transaction?.fromUser)) {
            this.addressEth = transaction?.addressFrom;
            transaction.isOwnerTransaction = true;
          }
          else if (
            _.toLower(eth.value) === _.toLower(transaction?.addressTo)
          ) {
            this.addressEth = transaction?.addressTo;
          }
          else {
            this.addressEth = transaction?.addressFrom;
          }
        });

        this.transactions[0].transactions.push({
          icon:
              transaction?.auToken?.logoMain ??
              transaction?.auToken?.mainImage?.name,
          name: _.isNil(this.addressEth)
            ? this.$t("transactions.unspecified")
            : this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4),
          addressFrom: _.get(transaction, "addressFrom", "-"),
          addressTo: _.get(transaction, "addressTo", "-"),
          addressEth: this.addressEth,
          amount: _.get(transaction, "amount", null),
          isConfirmed: _.get(transaction, "isConfirmed", false),
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: _.toLower(this.ourEth) == _.toLower(transaction?.addressTo),
          type: _.get(transaction, "methodName", "transfer"),
          decimals: _.get(transaction, "auToken.decimals", 2),
          indent: _.get(transaction, "auToken.indent", 2),
          mnemonic: _.get(transaction, "auToken.mnemonicEng", "-"),
          createdAt: _.get(transaction, "createdAt", "-"),
          hash: _.get(transaction, "hash", "-"),
          isOwnerTransaction: _.get(
            transaction,
            "isOwnerTransaction",
            transaction?.isOwnerTransaction
          ),
          projectId: this.id,
          projectName: this.currentCapital?.tokenBase?.nameEng,
          isVoting: transaction?.auCapital?.projectType === "voting"
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.o-header {
  display: flex;
  justify-content: space-between;
  align-items: end;

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
  }

  a {
    color: #279063;
  }
}
.o-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.o-period {
  font-size: 12px;
}
</style>