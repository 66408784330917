<template>
  <ImaskInput
    :key="imaskKey"
    :model-value="displayValue"
    class="input"
    :class="{'faded': faded}"
    :mask="isDot ? dotMask : Number"
    :placeholder="placeholder"
    :scale="indent"
    :max="maxResult"
    :normalize-zeros="true"
    :readonly="readonly"
    @update:modelValue="input"
  />
</template>

<script>
import BigNumber from "bignumber.js";
import { IMaskComponent } from "vue-imask";

export default {
  name: "AuDecimalInput",

  components: {
    "ImaskInput": IMaskComponent
  },

  props: {
    value: {
      type: String,
      default: ""
    },

    decimal: {
      type: Number,
      default: 0
    },

    indent: {
      type: Number,
      default: 2
    },

    placeholder: {
      type: String,
      default: ""
    },

    readonly: {
      type: Boolean,
      default: false
    },

    faded: {
      type: Boolean,
      default: false
    },

    isDot: {
      type: Boolean,
      default: false
    },

    max: {
      type: Number,
      default: null
    }
  },

  emits: ["input"],

  computed: {
    dotMask() {
      return new RegExp("^[0-9]*[.]?[0-9]{0," + this.indent + "}$");
    },

    imaskKey() {
      return `imask-${this.maxResult}`;
    },

    maxResult() {
      if (!this.max) {
        return null;
      }
      if (!this.decimal) {
        return this.max;
      }
      const num = new BigNumber(this.max);
      const divisor = new BigNumber(10).pow(this.decimal);
      return num.dividedBy(divisor).toNumber();
    },

    displayValue() {
      let value = this.value ? this.value : "0";
      value = value.replace(",", ".");

      if (this.decimal) {
        value = BigNumber(value).dividedBy(BigNumber(10).pow(this.decimal));
        value = value.isNaN() ? "" : value;
        if (!this.isDot) {
          value = value.toString(10).replace(".", ",");
        }
      }

      return value.toString();
    }
  },

  methods: {
    input(value) {
      if (value[value.length - 1] == ",") {
        value += "0";
      }

      if (this.decimal) {
        value = value.replace(",", ".");
        value = BigNumber(value).multipliedBy(BigNumber(10).pow(this.decimal)).toString(10);
      }

      this.$emit("input", value.toString());
    }
  }
};

</script>

<style scoped lang="scss">
.faded{
  color: var(--color-80)
}
</style>