<template>
  <div>
    <AuBreadcrumb
      :pages="breadcrumb"
    />
    <div class="card withdraw">
      <span class="title">
        {{ $t("escrowWidrawal.title") }}
      </span>
      <div v-if="!withdrawSuccess">
        <div class="body">
          <div>
            <AuInput
              v-model="token"
              readonly="true"
              required
              :label="$t('escrowWidrawal.selectLabel')"
            />
          </div>
          <div>
            <AuInput
              v-model="editCanWithdraw"
              :label="$t('withdraw.sumLabel')"
              type="Number"
              :decimal="decimals"
              :max="canWithdraw"
              required
            />
            <span class="notice">
              {{ $t("withdraw.sumNotice") }}
              <AuDecimalStatic
                :value="canWithdraw"
                :decimal="decimals"
                :show-addition="false"
              />
              {{ token }}
            </span>
          </div>
          <span class="error">
            {{ error }}
          </span>
          <div class="btn-place">
            <AuButton
              :disabled="!btnWidrawal"
              type="primary"
              class="btn"
              center
              :is-progress="progress"
              @click="sendWidrawal"
            >
              {{ $t("withdraw.btnWithdraw") }}
            </AuButton>
          </div>
        </div>
      </div>
      <div
        v-if="withdrawSuccess"
        class="success"
      >
        <span class="msg">{{ $t("withdraw.success") }}</span>
        <AuButton
          type="primary"
          width="40%"
          center
          @click="goToBriefcase"
        >
          {{ $t("withdraw.backBtnText") }}
        </AuButton>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import { mapActions } from "vuex";

export default {
  name: "Withdraw",
  data() {
    return {
      breadcrumb: [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("router.pages.briefcase"), path: "/briefcase" }
      ],
      token: "",
      tokenId: 0,
      editCanWithdraw: 0,
      currentCap: "",
      canWithdraw: "",
      withdrawnAmount: "",
      decimals: 2,
      indent: 2,
      amountWidrawal: 0,
      btnWidrawal: false,
      withdrawSuccess: false
    };
  },
  computed: {
    currentCapMax() {
      if (!this.currentCap) {
        return "";
      }
      else {
        return BigNumber(this.currentCap).dividedBy(BigNumber(10).pow(this.decimals)).toString();
      }
    }
  },
  watch: {
    editCanWithdraw(newValue) {
      this.editCanWithdraw = newValue;
      this.btnWidrawal = newValue > 0;
    }
  },

  async created() {
    await this.getCapital();
  },

  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      sendTokenEvents: "sendTokenEvents"
    }),

    async getCapital() {
      try {
        if (!this.$route.params.id) {
          return;
        }
        const item = await this.getAuCapitals(this.$route.params.id);
        this.token = item?.tokenQuote?.mnemonicEng;
        this.tokenId = item?.tokenQuote?.id;
        this.currentCap = item?.currentCap;
        this.canWithdraw = item?.canWithdraw;
        this.withdrawnAmount = item?.withdrawnAmount;
        this.decimals = item?.tokenQuote?.decimals;
        this.indent = item?.tokenQuote?.indent;
      }
      catch (e) {
        console.log("error", e);
      }
    },

    async sendWidrawal() {
      const params = {
        type: "token_capital_withdraw_request",
        auCapital: `/api/au_capitals/${this.$route.params.id}`,
        description: "withdraw_request",
        value: this.editCanWithdraw,
        decimals: this.decimals,
        isTask: true,
        isConfirmed: true
      };
      try {
        const resp = await this.sendTokenEvents(params);
        if (resp?.status == 201) {
          this.showSuccess();
          this.goToBriefcase();
        }
        else {
          this.showError(resp.data?.message);
        }
      }
      catch (error) {
        console.error(error);
        this.showError(this.$t("escrowWidrawal.message.error"));
      }
    },

    showSuccess() {
      this.$toast.success(
        this.$t("escrowWidrawal.message.success"),
        {
          duration: 8000
        }
      );
    },

    showError(message) {
      this.$toast.error(
        message,
        {
          duration: 8000
        }
      );
    },

    goToBriefcase() {
      this.$router.push({
        name: "briefcase",
        query: {
          selectedTitle: this.$t("briefcase.escrowWidrawalTabLabel")
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.withdraw {
  width: 100%;
  padding: 20px 22px 22px 20px;

  .title {
    font-size: 24px;
  }

  .body {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .select-label {
      font-size: 14px;
      margin-bottom: 8px;
      color: var(--color-50);
    }

    .notice {
      font-size: 12px;
    }

    .error {
      width: 100%;
      height: 20px;
      text-align: center;
      font-size: 14px;
      color: red;
    }

    .btn-place {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      @media screen and (max-width: 680px) {
        flex-direction: column;
        align-items: center;
        gap: 30px
      }
    }

    .btn {
      width: 40%;
      @media screen and (max-width: 680px) {
        width: 100%;
      }
    }
  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 50px;

    .msg {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>