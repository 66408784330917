<template>
  <div>
    <div>
      <AuBreadcrumb
        :pages="breadcrumb"
        :current-page-title="blogPage?.title"
      />
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div
        v-else
        class="pc-main"
      >
        <div class="card pc-fields pc-left-panel">
          <ImageNews
            :src="blogPage?.imageFile"
            :alt="blogPage?.title"
          />
          <DescriptionNews
            :title="blogPage?.title"
            :content="blogPage?.content"
          />
          <div class="pc-separator pc-indent pc-hidden" />
          <AuthorCapital
            class="pc-indent pc-hidden pc-fields"
            :class="{'pc-light': isLightTheme, 'pc-dark': !isLightTheme}"
            :user="project.user"
            :company="project.company"
          />

          <InvestBlock
            class="pc-indent pc-hidden"
            v-bind="project"
            @invest="openCard"
          />
          <div class="pc-separator pc-indent pc-hidden" />
          <Offers
            :id="project.id"
            :project-type="project.projectType"
            class="pc-hidden pc-indent"
          />
          <div class="pc-separator pc-indent pc-hidden" />
          <NewsForCapital
            v-if="blogPages?.data.length > 0"
            :news-info="blogPages?.data"
            class="pc-hidden pc-indent mb-0"
          />
        </div>
        <div class="pc-right-panel">
          <AuthorCapital
            class="card pc-fields pc-indent"
            :user="project.user"
            :company="project.company"
          />
          <InvestBlock
            class="card pc-fields pc-indent"
            v-bind="project"
            @invest="openCard"
          />
          <Offers
            :id="project.id"
            :project-type="project.projectType"
            class="card pc-fields pc-indent"
          />
          <NewsForCapital
            v-if="blogPages?.data.length > 0"
            :news-info="blogPages?.data"
            class="card pc-fields"
          />
        </div>
      </div>
      <CapitalModal
        v-if="showDialog"
        v-model="showDialog"
        :project="project"
        :invest-amount="investCount"
        @finish="investFinished"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AuthorCapital from "@/components/capital/AuthorCapital";
import BigNumber from "bignumber.js";
import CapitalModal from "@/components/capital/CapitalModal";
import DescriptionNews from "@/components/news/DescriptionNews.vue";
import ImageNews from "@/components/news/ImageNews.vue";
import InvestBlock from "@/components/capital/InvestBlock";
import NewsForCapital from "@/components/capital/NewsForCapital";
import Offers from "@/components/capital/Offers";
import ProgressLoader from "@/loaders/progress-bar";
import { convertCapital } from "@/components/capital/capitalHelper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SinglNews",
  components: {
    ImageNews,
    DescriptionNews,
    NewsForCapital,
    Offers,
    AuthorCapital,
    InvestBlock,
    CapitalModal,
    ProgressLoader
  },
  data() {
    return {
      loading: true,
      project: {},
      investCount: "",
      showDialog: false
    };
  },
  computed: {
    ...mapGetters({
      blogPages: "blogPages",
      tokens: "tokens",
      sectionCapital: "sectionCapital",
      countries: "country",
      isLightTheme: "isLightTheme",
      profile: "profile/profile",
      checkProfile: "profile/checkProfile"
    }),

    breadcrumb() {
      return [
        { key: 0, name: this.$t("router.pages.project"), path: "/projects" },
        { key: 1, name: this.project?.name || "Project", path: `/project-card/${this.project?.id}` }
      ];
    },

    blogPage() {
      return _.find(this.blogPages.data, item => item.auToken.id == this.project.tokenBase?.id);
    },

    isOuter() {
      return this.$route.meta.outer == true;
    },

    isAllowed() {
      if (this.isIssuer) {
        return false;
      }
      if (!this.project.allowedForUsers) {
        return true;
      }
      return _.some(this.profile.roles, role => role == this.project.allowedForUsers);
    },

    parentPath() {
      return this.isOuter ? "/outer-projects" : "/projects";
    },

    isIssuer() {
      if (this.$auth.user()) {
        return this.$auth.user().type == "issuer";
      }
      return false;
    },

    projectId() {
      return this.$route.params.id;
    },

    documents() {
      return _.filter(this.project.files, file => file.type != "image" && !file.isVideoEmbedded);
    },

    countryOptions() {
      return _.map(this.countries, c => ({ display: c.name, value: c.code }));
    },

    country() {
      return _.chain(this.countryOptions)
        .find({ value: this.project.countryCode })
        .get("display")
        .value();
    }

  },
  watch: {
    projectId: {
      immediate: true,
      async handler() {
        this.loading = true;
        await this.getSectionCapital();
        await this.getCapital();
        await this.getProjectNews();
        this.loading = false;
      }
    },
    checkProfile: {
      immediate: true,
      async handler() {
        await this.updateProject();
      }
    }
  },

  async mounted() {
    await this.getBlogPages();
    await this.getCountry();
  },

  methods: {
    ...mapActions({
      getBlogPages: "getBlogPages",
      getSectionCapital: "getSectionCapital",
      getAuCapitals: "capitals/getAuCapitals",
      getCountry: "getCountry"
    }),

    async getCapital() {
      try {
        if (!this.$route.params.id) {
          return;
        }
        const capital = await this.getAuCapitals(this.$route.params.id);
        this.project = convertCapital(capital, this.sectionCapital);
        this.project.country = this.country;
        this.project.user = capital?.user ?? {};
        this.project.tokenBase = capital?.tokenBase ?? {};
        this.project.tokenQuote = capital?.tokenQuote ?? {};
        this.project.smartContract = capital?.smartContract?.ethAddress?.value ?? "";
        this.project.expertVotes = capital?.expertVotes ?? "";
        this.project.minExpertVotes = capital?.minExpertVotes ?? "";
        this.project.hasEscrow = capital?.hasEscrow ?? "";
        this.project.terms = capital?.terms ?? "";
      }
      catch (e) {
        console.error("error", e);
      }
    },

    async getProjectNews() {
      const param = {
        page: 1,
        auToken: this.project.tokenBase?.id
      };
      try {
        await this.getBlogPages(param);
      }
      catch (e) {
        console.error(e);
      }
    },

    openCard(count) {
      if (this.isOuter) {
        window.open(process.env.VUE_APP_HOST + "project-card/" + this.project.id + "?outer=true", "_blank");
      }
      else {
        this.investCount = count;
        this.showDialog = !this.showDialog;
      }
    },

    async investFinished() {
      if (process.env.VUE_APP_WEBSOCKET_ENABLED != "true") {
        await new Promise(r => setTimeout(r, 20000));
        await this.updateProject();
      }
    },

    async updateProject() {
      const item = await this.getAuCapitals(this.$route.params.id);
      this.project.currentCap = item.currentCap;
      let percent = BigNumber(this.project.currentCap);
      percent = percent.multipliedBy(100);
      percent = percent.dividedBy(this.project.maxCap);
      this.project.progressProcent = percent.toNumber().toFixed(1);
    }
  }
};
</script>

<style lang="scss" scoped>
  .news-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .image-wrapper {
      img {
        width: 100%;
      }
    }
  }

.pc-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  @media screen and (max-width: 1000px)  {
    grid-template-columns: 1fr;
  }
}
.pc-fields {
  padding: 20px;
}
.pc-indent {
  margin-bottom: 25px;
}
.pc-separator {
  width: 100%;
  height: 3px;
  background-color: var(--sep-line-color);
}
.pc-hidden {
  display: none;
  @media screen and (max-width: 1000px)  {
    display: block;
  }
}
.pc-visible {
  display: flex;
  @media screen and (max-width: 1000px)  {
    display: none;
  }
}
.pc-left-panel {
  height: min-content;
}
.pc-right-panel {
  @media screen and (max-width: 1000px)  {
    display: none;
  }
}
.pc-light {
  background-color: #F2F1F7;
  border-radius: 20px;
}
.pc-dark {
  background-color: #0d1117;
  border-radius: 20px;
}
.pc-invest {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 22px;
  }
  img {
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(1);
  }
}
.ic-image {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
  object-fit: cover;
}
.news-link {
  color: #279063;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 1000px)  {
    margin-bottom: 15px;
  }
}
</style>