<template>
  <div>
    <div
      v-if="headerLine"
      class="header-line"
    >
      <div class="fs22">
        {{ $t("briefcase.escrowWidrawalTabLabel") }}
      </div>
      <RouterLink
        :to="{ path: '/briefcase', query: { selectedTitle: $t('briefcase.escrowWidrawalTabLabel') }}"
      >
        {{ $t('briefcase.allEscrow') }}
      </RouterLink>
    </div>

    <div class="content-line">
      <AuTable
        v-if="itemLoading || items.length > 0"
        class="briefcase-table briefcase-table-hiddable"
        :columns="columnsItems"
        :items="itemsModify"
        :loading="itemLoading"
        :property-style-function="() => ({ padding: '12px' })"
        :width-first="'auto'"
        :width-colum="'230px'"
      >
        <template #project="{ value }">
          <div class="block-name project-colum">
            <div class="explain uppercase">
              {{ value.name }}
            </div>
          </div>
        </template>
        <template #date="{ value }">
          <div class="block-name">
            <div class="link-text">
              {{ value.createdAt }}
            </div>
          </div>
        </template>
        <template #withdraw="{ value }">
          <span>{{ value.token }}</span>
        </template>
        <template #token="{ value }">
          <span>{{ value.token }}</span>
        </template>
        <template #sum="{ value }">
          <span>{{ value.amount }}</span>
        </template>
        <template #status="{ value }">
          <span v-if="value.isTaskDone">{{ $t('briefcase.approved') }}</span>
          <span v-else>{{ $t('briefcase.verification') }}</span>
        </template>
        <template
          v-if="isRoleExpert"
          #action="{ value }"
        >
          <div
            v-click-outside="closeAllMenus"
            class="container-menu"
          >
            <AuIcon
              icon="more"
              as-button
              @click="openMenu(value.index)"
            />
            <div
              v-if="showMenu[value.index]"
              class="context-menu"
            >
              <AuButton
                width="100%"
                center
                popup
                border-color="none"
                active-bg-color="none"
                hovered-bg-color="none"
                @click="confirmWD(value)"
              >
                {{ $t("briefcase.confirm") }}
              </AuButton>
            </div>
          </div>
        </template>
      </AuTable>
      <div
        v-else
        class="order-empty"
      >
        <img
          class="image"
          src="/img/dashboard/not_found_voting.svg"
          alt=""
        >
      </div>
    </div>

    <!-- for mobile -->
    <div class="briefcase-accordion">
      <div v-if="items != 0">
        <AuAccordion
          v-for="(item, index) in itemsModify"
          :key="item"
          :last-child="(index == items.length - 1)"
        >
          <template #title>
            <div class="block-name">
              <div class="link-text">
                {{ item.project.name }}
              </div>
            </div>
          </template>
          <template #content>
            <div class="content-info">
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("briefcase.createAt") }}</span>
                </div>
                <div class="col right">
                  {{ item.date.createdAt }}
                </div>
              </div>

              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("briefcase.withdrawIn") }}</span>
                </div>
                <div class="col right">
                  {{ item.withdraw.token }}
                </div>
              </div>

              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("briefcase.token") }}</span>
                </div>
                <div class="col right">
                  {{ item.token.token }}
                </div>
              </div>
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("briefcase.sum2Column") }}</span>
                </div>
                <div class="col right">
                  {{ item.sum.amount }}
                </div>
              </div>
              <div class="row">
                <div class="col info-title">
                  <span>{{ $t("briefcase.statusColumn") }}</span>
                </div>
                <div class="col right">
                  <span v-if="item.status.isTaskDone">{{ $t('briefcase.approved') }}</span>
                  <span v-else>{{ $t('briefcase.verification') }}</span>
                </div>
              </div>
            </div>
          </template>
        </AuAccordion>
      </div>

      <span v-else-if="items.length == 0">
        {{ $t('briefcase.noData') }}
      </span>

      <ProgressLoader
        v-if="itemLoading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
    </div>

    <AuPagination
      v-if="totalItems > itemsPerPage && isPagination"
      :hide="itemLoading"
      :total-items="totalItems"
      @pageChanged="pageChanged"
    />
  </div>
</template>

<script>
import _ from "lodash";
import isUserRole from "@/help/isUserRole";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ProgressLoader },
  props: {
    itemsPerPage: {
      type: Number,
      default: 30
    },
    headerLine: {
      type: Boolean,
      default: false
    },
    isPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pageId: 1,
      itemLoading: true,
      items: null,
      totalItems: null,
      showMenu: []
    };
  },

  computed: {
    ...mapGetters({
      language: "language",
      profile: "profile/profile",
      requestsToIssue: "requestsToIssue"
    }),

    isRoleExpert() {
      return Object.values(this.profile?.roles).includes("ROLE_EXPERT");
    },

    itemsModify() {
      return _.map(this.items, (item, i) => {
        const colums = {
          project: {
            name: item?.auCapital?.tokenBase?.nameEng
          },
          date: {
            createdAt: this.formatDateRange(item?.createdAt)
          },
          withdraw: {
            token: item?.auCapital?.tokenQuote?.mnemonicEng
          },
          token: {
            token: item?.auCapital?.tokenBase?.mnemonicEng
          },
          sum: {
            amount: item?.value
          },
          status: {
            isTaskDone: item?.isTaskDone
          }
        };
        if (this.isRoleExpert) {
          colums.action = {
            id: item?.id,
            index: i
          };
        }
        return colums;
      });
    },

    columnsItems() {
      const baseColumns = [
        { name: `${this.$t("briefcase.projects")}`, align: "start", slot: "project", property: "project" },
        ...this.columnsItemsAccordion
      ];

      if (this.isRoleExpert) {
        baseColumns.push({ name: `${this.$t("briefcase.action")}`, align: "center", slot: "action", property: "action" });
      }

      return baseColumns;
    },

    columnsItemsAccordion() {
      return [
        { name: `${this.$t("briefcase.createAt")}`, align: "center", slot: "date", property: "date" },
        { name: `${this.$t("briefcase.withdrawIn")}`, align: "center", slot: "withdraw", property: "withdraw" },
        { name: `${this.$t("briefcase.token")}`, align: "center", slot: "token", property: "token" },
        { name: this.$t("briefcase.sum2Column"), align: "center", slot: "sum", property: "sum" },
        { name: `${this.$t("briefcase.statusColumn")}`, align: "center", slot: "status", property: "status", decimal: true }
      ];
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },

  async mounted() {
    await this.getItems();
    this.closeAllMenus();
  },

  methods: {
    ...mapActions({
      getTokenEvents: "getTokenEvents",
      sendTokenEvents: "sendTokenEvents"
    }),

    async getItems() {
      this.itemLoading = true;
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        type: "token_capital_withdraw_request"
      };
      if (isUserRole() == "expert") {
        params.userVoteId = this.profile?.id;
      }

      try {
        const { data, totalItems } = await this.getTokenEvents(params);
        this.items = data;
        this.totalItems = totalItems;
      }
      catch (error) {
        console.log(error);
      }
      finally {
        this.itemLoading = false;
        this.closeAllMenus();
      }
    },

    async pageChanged(actualPage) {
      this.pageId = actualPage;
      this.getItems();
    },

    formatDateRange(startDate) {
      const formatMonth = (date) => {
        if (this.language === "ru") {
          return moment(date)
            .locale("ru")
            .format("DD MMM YYYY HH:mm")
            .replace(/\./, "")
            .replace(/[а-я]+/g, (month) => month.charAt(0).toUpperCase() + month.slice(1));
        }
        return moment(date).locale("en").format("DD MMM YYYY HH:mm");
      };
      return formatMonth(startDate);
    },

    closeAllMenus() {
      this.showMenu = _.map(this.items, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    async confirmWD(value) {
      this.itemLoading = true;
      const params = {
        task: `/api/token_events/${value.id}`,
        type: "token_capital_withdraw_approve",
        isTask: true,
        description: "withdraw_approve"
      };
      try {
        const resp = await this.sendTokenEvents(params);
        if (resp?.status == 200 || resp?.status == 201) {
          this.showSuccess(this.$t("briefcase.messages.successWd"));
        }
        else {
          this.showError(resp.data?.message || this.$t("briefcase.messages.errorWd"));
        }
      }
      catch (error) {
        this.showError(error?.message || this.$t("briefcase.messages.errorWd"));
      }
      finally {
        this.itemLoading = false;
        this.closeAllMenus();
      }
    },
    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }

};
</script>


<style scoped lang="scss">
.header-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  a {
    padding-top: 4px;
    font-size: 13px;
    color: #279063;
    text-decoration: none;
  }
}
.briefcase {
  &-info {
    padding: 11px 20px 0 18px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
      margin-bottom: 30px;
    }
  }

  &-table {
    margin-top: 10px;
    margin-bottom: 10px;

    &-hiddable {
      @media screen and (max-width:590px) {
        display: none;
      }
    }
  }

  &-accordion {
    margin-top: 10px;
    display: none;

    @media screen and (max-width:590px) {
      display: block;
    }
  }
}

.spot-balance {
  color: var(--color-50);
  font-weight: 500;
  font-size: 12px;
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }

  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;

    &-title {
      color: var(--color-60)
    }

    .row {
      font-weight: 450;
      font-size: 12px;

      .col:last-child {
        text-align: end;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

}

.icon-with-bg {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  opacity: var(--opacity);
}

.link-container {
  display: flex;
  align-items: center;
}

.link {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  .block-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 130px;
    .link-text {
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--color-00);
        text-decoration-style: dashed;
        text-underline-offset: 4px;
      }
    }
    .explain {
      font-weight: 450;
      font-size: 12px;
      color: var(--color-60);
      @media screen and (max-width:384px) {
        line-height: 1.1;
      }
    }
  }
}
.briefcase-tooltip {
  margin-left: 2px;
}
.t-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
}
.progress_line {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.progress_text {
  display: flex;
  justify-content: space-between;
  font-weight: 450;
  font-size: 12px;
  color: var(--color-20);
}
.indent_progress {
  margin-top: 6px;
  width: 100%;
}
.status {
  width: 135px;
}
.uppercase::first-letter {
  text-transform: uppercase;
}
.order-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 400px;
    height: 300px;
  }
}
.right {
  text-align: end;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 10;
  top: 30px;
}
.project-colum {
  width: 130px;
}
</style>

