<template>
  <div>
    <div>
      {{ $t("auCapitals.news.title") }}
    </div>
    <div class="nfc-main">
      <div
        v-for="(news, i) of newsArray"
        :key="i"
      >
        <img
          class="news-image"
          :src="news.image"
          alt=""
        >
        <RouterLink
          class="news-link"
          :to="`/news/${news.newsLink}`"
        >
          {{ news.title }}
        </RouterLink>
        <div
          class="news-text"
          :class="{'news-text-black': !isLightTheme}"
        >
          {{ news.subtitle }}
        </div>
        <div class="news-divider" />
        <div
          class="news-text"
          :class="{'news-text-black': !isLightTheme}"
        >
          {{ news.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import formatDate from "@/help/dateSupport";
import { mapGetters } from "vuex";

export default {
  name: "NewsForCapital",
  props: {
    newsInfo: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      language: "language"
    }),

    newsArray() {
      const bufArr = this.newsInfo ?? [];
      bufArr.length = bufArr.length < 3 ? bufArr.length : 2;
      return _.map(bufArr, item => ({
        image: item?.imageFile,
        title: item?.title,
        subtitle: item?.subtitle,
        newsLink: `${item?.auCapital["@id"].split("/").pop()}`,
        date: formatDate(item?.dateForNews, this.language)
      }));
    }
  }
};
</script>

<style scoped lang="scss">
.nfc-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 15px
}
.news-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}
.news-divider {
  width: 100%;
  height: 3px;
  border: 1px solid #F9F9F9;
  background-color: #F9F9F9;
  margin: 8px 0 10px 0;
}
.news-text {
  color: #33333399;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.news-text-black {
  color: #CCCCCC99;
}
.news-link {
  display: block;
  margin-bottom: 10px;
  color: #279063;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
}
</style>