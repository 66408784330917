<template>
  <div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="panel-space"
    >
      <div class="card panel-indent">
        <div class="panel-header">
          <span class="panel-title">
            {{ $t("portfolioExplorer.title") }}
          </span>
          <div class="buttons">
            <!-- <AuButton
              active-bg-color="#DFFEF1"
              font-color="#279063"
            >
              {{ $t("portfolioExplorer.month") }}
            </AuButton>
            <AuButton
              active-bg-color="#DFFEF1"
              font-color="#279063"
            >
              2024
            </AuButton>
            <AuButton
              active-bg-color="#DFFEF1"
              font-color="#279063"
            >
              2025
            </AuButton> -->
            <AuButton type="primary">
              {{ $t("portfolioExplorer.all") }}
            </AuButton>
          </div>
        </div>
        <Apexchart
          :series="series"
          :options="options"
          height="400px"
        />
      </div>
      <div class="card panel-indent">
        <div class="panel-title">
          {{ $t("portfolioExplorer.titleMetrics") }}
        </div>
        <div
          v-for="(period, key) of metrics"
          :key="key"
        >
          <div
            v-if="period.title"
            class="metrics-sub-title"
          >
            {{ period.title }}
          </div>
          <div class="metrics">
            <div
              v-for="(item, index) of period.data"
              :key="index"
              class="record"
            >
              <div class="name">
                <img
                  :src="item.icon"
                  alt="icon"
                >
                <span>
                  {{ item.name }}
                </span>
              </div>
              <div class="separator" />
              <span class="value">
                {{ item.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PortfolioExplorer",
  components: { ProgressLoader },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      balanceData: [],
      profitData: [],
      page: 1,
      itemsPerPage: 10,
      series: [{
        data: [],
        color: "#28E593",
        name: this.$t("incomeChart.balance")
      },
      {
        data: [],
        color: "#00AEEF",
        name: this.$t("incomeChart.profit")
      }
      ],
      categories: [],
      capital: {}
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      portfolioTracker: "capitals/portfolioTracker",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    options() {
      return {
        chart: {
          type: "area",
          toolbar: {
            show: false
          },
          parentHeightOffset: 0
        },
        stroke: {
          curve: "smooth"
        },
        fill: {
          type: "gradient",
          colors: ["#28E593", "#00AEEF"],
          gradient: {
            opacityFrom: 0.7,
            opacityTo: 0.1
          }
        },
        tooltip: {
          enabled: true
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: "top"
        },
        grid: {
          show: false,
          row: {
            colors: ["#28E5931A"],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: this.categories
        },
        yaxis: {
          labels: {
            formatter: (v, _) => {
              return `${v} ${this.defaultTokenMnemonic}`;
            }
          }
        }
      };
    },

    balance() {
      return this.portfolioTracker.at(0)?.balance ?? "None";
    },

    profit() {
      return this.portfolioTracker.at(0)?.profit ?? "None";
    },

    drawDown() {
      return this.portfolioTracker.at(0)?.drawdownPercents ?? "None";
    },

    sharp() {
      return this.portfolioTracker.at(0)?.sharpCoef ?? "None";
    },

    start() {
      return this.capital?.createdAt ? moment(this.capital?.createdAt).format("DD.MM.YYYY") : "None";
    },

    roi() {
      return this.portfolioTracker.at(0)?.roi ?? "None";
    },

    currency() {
      return this.capital?.tokenQuote?.mnemonicEng ?? "None";
    },

    monthProfit() {
      return this.portfolioTracker.at(0)?.maxMonthlyProfitPercents ?? "None";
    },

    monthDrawdown() {
      return this.portfolioTracker.at(0)?.maxMonthlyDrawdownPercents ?? "None";
    },

    risk() {
      if (!this.capital?.risk) {
        return this.language == "ru" ? "Не выбран" : "Not set";
      }
      switch (this.capital?.risk) {
        case "conservative":
          return this.language == "ru" ? "Консервативный" : "Conservative";
        case "balanced":
          return this.language == "ru" ? "Сбалансированный" : "Balanced";
        case "aggressive":
          return this.language == "ru" ? "Агрессивный" : "Aggressive";
        default:
          return this.capital?.risk;
      }
    },

    allPeriod() {
      return [
        { name: this.$t("portfolioExplorer.startTrading"), value: this.start, icon: "/img/capital/calendar.svg" },
        { name: this.$t("portfolioExplorer.balance"), value: this.balance + this.capital?.tokenQuote?.mnemonicEng, icon: "/img/capital/wallet.svg" },
        { name: this.$t("portfolioExplorer.totalProfit"), value: this.profit + this.capital?.tokenQuote?.mnemonicEng, icon: "/img/capital/wallet.svg" },
        { name: this.$t("portfolioExplorer.risk"), value: this.risk, icon: "/img/capital/speedometer.svg" },
        { name: this.$t("portfolioExplorer.drawDawn"), value: this.drawDown + "%", icon: "/img/capital/graph.svg" },
        { name: this.$t("portfolioExplorer.mainCurrency"), value: this.currency, icon: "/img/capital/dollar.svg" },
        { name: this.$t("portfolioExplorer.roi"), value: this.roi, icon: "/img/capital/calendar.svg" },
        { name: this.$t("portfolioExplorer.sharp"), value: this.sharp, icon: "/img/capital/plusminus.svg" }
      ];
    },

    month() {
      return [
        { name: this.$t("portfolioExplorer.profit"), value: this.monthProfit + "%", icon: "/img/capital/calendar.svg" },
        { name: this.$t("portfolioExplorer.lesion"), value: this.monthDrawdown + "%", icon: "/img/capital/wallet.svg" }];
    },

    metrics() {
      return [
        { title: null, data: this.allPeriod },
        { title: this.$t("portfolioExplorer.monthTitle"), data: this.month }];
    }
  },
  async mounted() {
    this.loading = true;
    await this.getData();
    await this.getCapital();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      getPortfolioTracker: "capitals/getPortfolioTracker"
    }),

    async getCapital() {
      try {
        this.capital = await this.getAuCapitals(this.id);
      }
      catch (e) {
        console.error(e);
      }
    },

    async getData() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auCapitalId: this.id
      };
      this.categories = [];
      this.balanceData = [];
      this.profitData = [];
      try {
        const resp = await this.getPortfolioTracker(params);
        for (const item of resp.data) {
          this.categories.unshift(moment(item?.createdAt).format("DD.MM.YYYY"));
          this.balanceData.unshift(item?.balance);
          this.profitData.unshift(item?.profit);
        }
        this.series[0].data = this.balanceData;
        this.series[1].data = this.profitData;
      }
      catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.panel-space {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.panel-indent {
  padding: 20px;
}
.panel-title {
  font-size: 24px;
  font-weight: 600;
}
.panel-header {
  display: flex;
  justify-content: space-between;

  .buttons {
    display: flex;
    gap: 10px;
  }
}
.metrics-sub-title {
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
}
.metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 25px;
  margin-top: 15px;

  .name {
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 12px;
      color: var(--color-50);
    }
  }

  .value {
    font-size: 12px;
    color: var(--text-color);
  }

  .record {
    display: flex;
    align-items: end;
    gap: 5px;
  }

  .separator {
    height: 2px;
    flex: 1;
    border-bottom: 1px dashed var(--color-50);
    margin-bottom: 4px;
    opacity: 0.5;
  }
}
</style>