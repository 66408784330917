import actions from "./actions";

export default {
  namespaced: true,
  state: {
    auCapitals: [],
    portfolioTracker: [],
    searchedAuCapitals: [],
    investors: [],
    investorsTotal: null,
    portfolioTotal: null,
    dataLoading: false,
    followings: []
  },
  getters: {
    auCapitals: state => {
      return state.auCapitals;
    },

    portfolioTracker: state => {
      return state.portfolioTracker;
    },

    searchedAuCapitals: state => {
      return state.searchedAuCapitals;
    },

    investors: state => {
      return state.investors;
    },

    investorsTotal: state => {
      return state.investorsTotal;
    },

    portfolioTotal: state => {
      return state.portfolioTotal;
    },

    dataLoading: state => {
      return state.dataLoading;
    },

    followings: state => {
      return state.followings;
    }
  },
  mutations: {
    setAuCapitals(state, capitals) {
      state.auCapitals = capitals;
    },

    setPortfolioTracker(state, pTracker) {
      state.portfolioTracker = pTracker;
    },

    setSearchedAuCapitals(state, capitals) {
      state.searchedAuCapitals = capitals;
    },

    setInvestors(state, investors) {
      state.investors = investors;
    },

    setInvestorsTotal(state, investorsTotal) {
      state.investorsTotal = investorsTotal;
    },

    setPortfolioTotal(state, portfolioTotal) {
      state.portfolioTotal = portfolioTotal;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    },

    setFollowings(state, followings) {
      state.followings = followings;
    }
  },
  actions
};