<template>
  <div>
    <div class="news-title">
      {{ title }}
    </div>
    <div class="news-descriptions">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionNews",
  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.news-title {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 15px;
}
.news-subtitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.news-descriptions {
  color: var(--color-50);
  white-space: pre-line;
  margin-bottom: 15px;
}
</style>