<template>
  <ProgressLoader
    v-if="loading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />
  <div v-else>
    <AuBreadcrumb
      :pages="breadcrumb"
      :current-page-title="currentRoutName"
    />
    <DaoForm
      v-if="showDaoForm"
      :edit-mode="editMode"
      :dao-request="editDao"
      :tokens-items="tokensItems"
      @add="addRequest"
      @edit="editRequest"
      @cancel="showDaoForm=false"
    />
    <div v-else>
      <div class="card dao">
        <div class="header">
          <span class="title">
            {{ $t("daoPage.title") }}
          </span>
          <AuButton
            border-radius="10"
            bordered
            center
            type="primary"
            width="150px"
            @click="showAddDaoForm"
          >
            {{ $t("daoPage.button") }}
          </AuButton>
        </div>
        <div class="explain">
          {{ $t("daoPage.project") }}
          <span>{{ projectName }}</span>
        </div>
        <AuTable
          :columns="columns"
          :items="requestsItems"
        >
          <template #action="{value}">
            <div
              v-click-outside="closeAllMenus"
              class="container-menu"
            >
              <AuIcon
                icon="more"
                as-button
                @click="openMenu(value.index)"
              />
              <div
                v-if="showMenu[value.index]"
                class="context-menu"
              >
                <AuButton
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="showEditDaoForm(value)"
                >
                  {{ $t("daoPage.menuEdit") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="showDelete(value)"
                >
                  {{ $t("daoPage.menuDelete") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="goToResult(value.id)"
                >
                  {{ $t("daoPage.menuResult") }}
                </AuButton>
                <AuButton
                  width="100%"
                  center
                  popup
                  border-color="none"
                  active-bg-color="none"
                  hovered-bg-color="none"
                  @click="showApprove(value)"
                >
                  {{ $t("daoPage.menuApprove") }}
                </AuButton>
              </div>
            </div>
          </template>
        </AuTable>
        <AuPagination
          v-if="daoRequestsTotal > itemsPerPage"
          :total-items="daoRequestsTotal"
          @pageChanged="changePage"
        />
      </div>
      <AuModal
        v-model="showDialog"
        width="490px"
      >
        <template #header>
          <span class="modal_title">
            {{ action }}
          </span>
        </template>
        <template #body>
          <div>
            <span>{{ mode === 'delete' ? $t("daoPage.modalIsDelete") : $t("daoPage.modalApprove") }}</span>
          </div>
        </template>
        <template #footer>
          <div class="d-flex justify-content-end mt-3">
            <AuButton
              class="me-3"
              border-radius="10"
              bordered
              center
              width="150px"
              @click="doAction"
            >
              {{ action }}
            </AuButton>
            <AuButton
              border-radius="10"
              bordered
              center
              width="150px"
              @click="showDialog=false"
            >
              {{ $t("daoPage.buttonCancel") }}
            </AuButton>
          </div>
        </template>
      </AuModal>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import DaoForm from "@/components/DaoForm";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DAORequests",
  components: { DaoForm, ProgressLoader },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showMenu: [],
      columns: [
        { name: this.$t("daoPage.firstColumn"), align: "start" },
        { name: this.$t("daoPage.secondColumn"), align: "center" },
        { name: this.$t("daoPage.thirdColumn"), align: "center" },
        { name: this.$t("daoPage.fourthColumn"), align: "center" },
        { name: this.$t("daoPage.fifthColumn"), align: "center" },
        { name: this.$t("daoPage.sixColumn"), align: "center", slot: "action" }
      ],
      statuses: {
        draft: this.$t("daoPage.statusDraft"),
        prepared: this.$t("daoPage.statusPrepared"),
        ready: this.$t("daoPage.statusReady"),
        process: this.$t("daoPage.statusProcess"),
        closed: this.$t("daoPage.statusClosed")
      },
      page: 1,
      itemsPerPage: 30,
      showDialog: false,
      showDaoForm: false,
      editMode: false,
      editDao: {},
      modifyId: null,
      mode: null,
      currentCapital: {},
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      daoRequests: "dao/daoRequests",
      daoRequestsTotal: "dao/daoRequestsTotal",
      daoElements: "dao/daoElements",
      voitingTokens: "dao/voitingTokens"
    }),

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("router.pages.project"), path: "/my-projects" }
      ];
      if (this.projectName) {
        breadcrumb.push({ key: breadcrumb?.length, name: this.projectName, path: `/project-card/${this.id}` });
      }
      return breadcrumb;
    },

    currentRoutName() {
      return this.showDaoForm ? this.$t(`daoPage.${this.editMode ? "editDao" : "addDao"}`) : "";
    },

    projectName() {
      return this.currentCapital?.tokenBase?.nameEng || null;
    },

    requestsItems() {
      return _.map(this.daoRequests, (item, index) => {
        return {
          name: item.name,
          tokenLimit: item.tokenLimit,
          startAt: moment(item.startAt).format("DD.MM.YYYY"),
          endAt: moment(item.endAt).format("DD.MM.YYYY"),
          status: _.get(this.statuses, item.status, "-"),
          action: {
            id: item.id,
            index: index,
            name: item.name,
            description: item.description,
            tokenLimit: item.tokenLimit,
            startAt: item.startAt,
            endAt: item.endAt,
            status: item.status,
            tokenDao: item?.tokenDao ?? null
          }
        };
      });
    },

    action() {
      if (this.mode === "delete") {
        return this.$t("daoPage.actionDelete");
      }
      return this.$t("daoPage.actionApprove");
    },

    tokensItems() {
      return _.map(this.voitingTokens, item => ({
        display: item.nameEng,
        value: `/api/au_tokens/${item.id}`
      }));
    }
  },

  async mounted() {
    if (this.$auth.user().type !== "issuer") {
      this.$router.push({ name: "base" });
    }
    this.loading = true;
    this.currentCapital = await this.getAuCapitals(this.id);
    await this.getDao();
    await this.getVoitingTokens();
    this.closeAllMenus();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getDaoRequests: "dao/getDaoRequests",
      getDaoElements: "dao/getDaoElements",
      addDaoRequest: "dao/addDaoRequest",
      editDaoRequest: "dao/editDaoRequest",
      deleteDaoRequest: "dao/deleteDaoRequest",
      addDaoElement: "dao/addDaoElement",
      editDaoElement: "dao/editDaoElement",
      deleteDaoElement: "dao/deleteDaoElement",
      getVoitingTokens: "dao/getVoitingTokens",
      getAuCapitals: "capitals/getAuCapitals"
    }),

    async getDao() {
      await this.getDaoRequests({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      });
    },

    closeAllMenus() {
      this.showMenu = _.map(this.daoRequests, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    async changePage(page) {
      this.page = page;
      await this.getDao();
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    },

    async addElements(requestId, elements) {
      for (const item of elements) {
        if (item.name.length > 0) {
          await this.addDaoElement({
            name: item.name,
            request: `/api/dao_requests/${requestId}`
          });
        }
      }
    },

    async addRequest(body) {
      this.showDaoForm = false;
      body["createdAt"] = moment().format("DD.MM.YYYY HH:mm:ss");
      body["owner"] = `/api/users/${this.$auth.user().id}`;
      body["tokenDao"] = `/api/au_tokens/${this.currentCapital?.tokenBase?.id}`;
      try {
        await this.addDaoRequest(body);
        await this.getDao();
        await this.addElements(this.daoRequests[0].id, body.elements);
        this.showSuccess(this.$t("daoPage.success.add"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.add"));
      }
    },

    doAction() {
      this.showDialog = false;
      if (this.mode === "delete") {
        this.deleteRequest();
      }
      else {
        this.approve();
      }
    },

    showAddDaoForm() {
      this.editMode = false;
      this.showDaoForm = true;
    },

    async showEditDaoForm(value) {
      if (value.status === "process" || value.status === "closed") {
        const prefix = value.status === "process" ?
          this.$t("daoPage.error.processed") : this.$t("daoPage.error.closed");
        this.showError(`${prefix} ${this.$t("daoPage.error.editEmbargo")}`);
        return;
      }
      this.modifyId = value.id;
      this.editDao = {
        name: value.name,
        description: value.description,
        tokenLimit: value.tokenLimit.toString(10),
        startAt: value.startAt,
        endAt: value.endAt,
        useTokens: value.tokenDao !== null,
        selectedToken: value.tokenDao
      };
      await this.getDaoElements(value.id);
      this.editDao["elements"] = _.map(this.daoElements, item => ({ id: item.id, name: item.name }));
      if (_.isEmpty(this.editDao.elements)) {
        this.editDao.elements = [{ name: "" }];
      }
      this.editMode = true;
      this.showDaoForm = true;
    },

    async showDelete(value) {
      this.mode = "delete";
      this.modifyId = value.id;
      this.showDialog = true;
    },

    async showApprove(value) {
      this.mode = "approve";
      this.modifyId = value.id;
      this.showDialog = true;
    },

    async changeElements(requestId, elements) {
      for (const item of this.daoElements) {
        const index = _.findIndex(elements, el => el.id == item.id);
        if (index !== -1) {
          if (elements[index].name !== item.name) {
            if (elements[index].name.length > 0) {
              await this.editDaoElement({
                id: item.id,
                body: {
                  name: elements[index].name,
                  request: `/api/dao_requests/${requestId}`
                }
              });
            }
            else {
              await this.deleteDaoElement(item.id);
            }
          }
          elements.splice(index, 1);
        }
        else {
          await this.deleteDaoElement(item.id);
        }
      }
      for (const item of elements) {
        if (item.name.length > 0) {
          await this.addDaoElement({
            name: item.name,
            request: `/api/dao_requests/${requestId}`
          });
        }
      }
    },

    async editRequest(body) {
      this.showDaoForm = false;
      body["owner"] = `/api/users/${this.$auth.user().id}`;
      try {
        await this.editDaoRequest({ id: this.modifyId, body: body });
        await this.changeElements(this.modifyId, body.elements);
        await this.getDao();
        this.showSuccess(this.$t("daoPage.success.edit"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.edit"));
      }
    },

    async deleteRequest() {
      try {
        await this.getDaoElements(this.modifyId);
        for (const item of this.daoElements) {
          await this.deleteDaoElement(item.id);
        }
        await this.deleteDaoRequest(this.modifyId);
        await this.getDao();
        this.showSuccess(this.$t("daoPage.success.delete"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.delete"));
      }
    },

    goToResult(id) {
      this.$router.push({ name: "dao events", params: { id: id } });
    },

    async approve() {
      const body = {
        status: "prepared"
      };
      try {
        await this.editDaoRequest({ id: this.modifyId, body: body });
        await this.getDao();
        this.showSuccess(this.$t("daoPage.success.approve"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.approve"));
      }
    }
  }
};
</script>

<style scoped lang="scss">
.dao{
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title{
    font-size: 24px;
    color: var(--text-color);
    line-height: 1.1;
  }
  .explain {
    margin-bottom: 20px;
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    .data {
      font-weight: 500;
      font-size: 14px;
    }
  }
}
.modal_title {
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal_switch{
  padding: 10px;
  border: 1px solid var(--scrollbar-color);
  border-radius: 10px;
  background: var(--color-70);
  cursor: pointer;
}
.modal_checked{
  background: var(--color-50);
}
.modal_border {
  border: 1px solid var(--scrollbar-color);
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  min-height: 450px;
  height: content-box;
  overflow-y: auto;
}
.modal_gap{
  display: flex;
  gap: 5px;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 10;
  top: 30px;
}
.dao-indent {
  margin-top: 10px;
}
</style>